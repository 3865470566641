import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { PaypalService } from 'src/app/api/paypal.service';
import { UserService } from 'src/app/api/user.service';

@Component({
  selector: 'cup-cancel-subscription',
  templateUrl: './cancel-subscription.component.html',
  styleUrls: ['./cancel-subscription.component.css']
})
export class CancelSubscriptionComponent implements OnInit {
  private user: any;
  public data: any;
  private saving = false;
  private paypalServiceSubcription: Subscription;
  constructor(private paypalService: PaypalService,public userService: UserService,public snackbar: MatSnackBar, public fbuilder: FormBuilder) { 
    this.data = this.fbuilder.group({
      reason: new FormControl('',Validators.maxLength(120))
    });
  }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.paypalServiceSubcription = this.paypalService.paypalChanged.subscribe(data =>{
      if(data.status == "success"){
        if (this.saving) {
          this.clearForm();
          var sb = document.getElementById("save") as HTMLButtonElement;
          sb.disabled = false;
          this.snackbar.open('Cancellation Successful', '', { duration: 2000, panelClass: ['snackbar-success'] });
        }
        
      }else{
        this.snackbar.open('Failed to Cancel','Contact support@cre-denseupload.com',{duration: 3000, panelClass: ['snackbar-error']});
      }
    });
  }
  ngOnDestroy(){
    this.paypalServiceSubcription.unsubscribe();
  }
  public send(){
    if(this.data.status == "INVALID"){
      this.snackbar.open('Please ensure reason is 120 characters or less','',{duration: 3000, panelClass: ['snackbar-error']});
    }else{
      var formData = new FormData();
      formData.append("reason", this.data.value.reason);
      this.paypalService.cancelSubscription(this.user,formData);
      this.snackbar.open('Sending in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
      this.saving = true;
      var sb = document.getElementById("save") as HTMLButtonElement;
      sb.disabled = true;
    }
  }
  public resetForm(){
    this.clearForm();
  }
  private clearForm(){
    this.data.reset();
  }
}
