import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RegisterComponent } from './landing-page/register/register.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { SideBarComponent } from './user-dashboard/side-bar/side-bar.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UserDashboardModule } from './user-dashboard/user-dashboard.module';
import { MaterialModule } from './material/material.module';
import { MAT_LABEL_GLOBAL_OPTIONS} from '@angular/material';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { ViewPacketComponent } from './view-packet/view-packet.component';
import { CliniciansComponent } from './clinicians/clinicians.component';
import { EmployersComponent } from './employers/employers.component';
import { NewsComponent } from './news/news.component';
import { FaqComponent } from './faq/faq.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { LegalComponent } from './legal/legal.component';
import { FindUsernameComponent } from './sign-in/find-username/find-username.component';
import { ResetPasswordComponent } from './sign-in/reset-password/reset-password.component';
import { LegalDialogComponent } from './view-packet/legal-dialog/legal-dialog.component';
import { CaliLegalComponent } from './cali-legal/cali-legal.component';
import { CaliOptOutComponent } from './cali-opt-out/cali-opt-out.component';
import { SubscriptionLegalComponent } from './subscription-legal/subscription-legal.component';
import { DemoDialogComponent } from './landing-page/demo-dialog/demo-dialog.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { AdvertiseComponent } from './layout/advertise/advertise.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    RegisterComponent,
    ContactUsComponent,
    AboutUsComponent,
    PageNotFoundComponent,
    ViewPacketComponent,
    CliniciansComponent,
    EmployersComponent,
    NewsComponent,
    FaqComponent,
    SignInComponent,
    TermsComponent,
    PrivacyComponent,
    LegalComponent,
    FindUsernameComponent,
    ResetPasswordComponent,
    LegalDialogComponent,
    CaliLegalComponent,
    CaliOptOutComponent,
    SubscriptionLegalComponent,
    DemoDialogComponent,
    HeaderComponent,
    FooterComponent,
    AdvertiseComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    UserDashboardModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [{provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: {float: 'always'}}],
  bootstrap: [AppComponent],
  entryComponents: [LandingPageComponent, RegisterComponent, ContactUsComponent, DemoDialogComponent, SignInComponent,TermsComponent, PrivacyComponent, LegalComponent, FindUsernameComponent, ResetPasswordComponent,LegalDialogComponent,CaliLegalComponent, CaliOptOutComponent, SubscriptionLegalComponent],
  exports:[]
})
export class AppModule { }
