import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private http: HttpClient) { }

  public news: any;
  public newsChanged = new Subject<any>();

  public getNews(){
    this.http.get(`/rest/getNews.php`).subscribe(data => {
      this.news = data;
      this.newsChanged.next(this.news);
    });
  }
}
