import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContinuingEducationService {
  public ce: any;
  public ceChanged = new Subject<any>();

  constructor(private http: HttpClient) { }
  public getContinuingEducation(userId: String){
    this.http.get(`/rest/getContEdus.php?user=${userId}`).subscribe(data => {
      this.ce = data;
      this.ceChanged.next(this.ce);
    });
  }
  public addContinuingEducation(user: any, data: any){
    this.http.post(`/rest/insertContEdus.php?user=${user}`,data).subscribe(result => {
      this.ce = result;
      this.ceChanged.next(this.ce);
    });
  }
  public updateContinuingEducation(user: any, data: any){
    this.http.post(`/rest/updateContEdus.php?user=${user}`,data).subscribe(result => {
      this.ce = result;
      this.ceChanged.next(this.ce);
    });
  }
}
