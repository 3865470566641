import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  public formatDate(date: any){
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    var returnDate:any = [month, day, year].join('/');
    if(returnDate[0] === 'N' || returnDate === '12/31/1969'){
      returnDate = '';
    }
    return returnDate;
  }
  public checkFileExtention(file: File){
    var acceptedExt = ['jpg','tiff','tif','pdf'];
    var ext = file.name.split('\.');
    if(acceptedExt.indexOf(ext[1].toLowerCase()) > -1){
      return true;
    }else{
      return false;
    }
  }
}
