import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/api/user.service';
import { Subscription } from 'rxjs';
import { FellowshipService } from 'src/app/api/fellowship.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { FileViewDialogComponent } from '../file-view-dialog/file-view-dialog.component';
import { DeleteService } from 'src/app/api/delete.service';
import { UpdateFellowshipDialogComponent } from './update-fellowship-dialog/update-fellowship-dialog.component';
import { UtilService } from 'src/app/api/util.service';
import { AddDocumentService } from 'src/app/api/add-document.service';
import { AddDocumentDialogComponent } from '../add-document-dialog/add-document-dialog.component';
import { PicklistService } from 'src/app/api/picklist.service';
import { UpdateOthFellowshipDialogComponent } from './update-oth-fellowship-dialog/update-oth-fellowship-dialog.component';

@Component({
  selector: 'cup-fellowship',
  templateUrl: './fellowship.component.html',
  styleUrls: ['./fellowship.component.css']
})
export class FellowshipComponent implements OnInit {
  private user: any;
  public title: any = "";
  public picker: any;
  public expicker: any;
  public data: any;
  public funding: any;
  public award: any;
  public pub: any;
  public present: any;
  public volunteer: any;
  public heading = "Fellowship";
  public fundingType: string [] = ['Scholarships','Fellowship Funding','Research Funding','Other'];
  private fellowshipSubscription: Subscription;
  private fellowshipOthSubscription: Subscription;
  private deleteSubscription: Subscription;
  private documentSubscription: Subscription;
  private picklistSubscription: Subscription;
  public fellowship: any[] = [];
  public fellowshipOth: any[] = [];
  public fundingList: any[] = [];
  public awardList: any[] = [];
  public pubList: any[] = [];
  public presentList: any[] = [];
  public volunteerList: any[] = [];
  private saving = false;
  public picklist;
  constructor(public fbuilder: FormBuilder, private userService: UserService,private utilService: UtilService,
    private fellowshipService: FellowshipService,private addDocumentService: AddDocumentService,private deleteService: DeleteService,
    public dialog: MatDialog,public snackbar: MatSnackBar,public picklistService: PicklistService) {
    this.data = this.fbuilder.group({
      date: new FormControl('', Validators.required),
      exDate: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      zipcode: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      fileSource: new FormControl()
    });
    this.funding = this.fbuilder.group({
      type: new FormControl('', Validators.required),
      ftype: new FormControl(),
      description: new FormControl('', Validators.required)
    });
    this.award = this.fbuilder.group({
      description: new FormControl('', Validators.required)
    });
    this.pub = this.fbuilder.group({
      description: new FormControl('', Validators.required)
    });
    this.present = this.fbuilder.group({
      description: new FormControl('', Validators.required),
      fileSource: new FormControl()
    });
    this.volunteer = this.fbuilder.group({
      description: new FormControl('', Validators.required)
    });
  }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.title = this.userService.getTitle();
    this.picklistService.getStates();
    if(this.title == 'RN'){
      this.heading = "Specialized Training";
    }
    this.fellowshipService.getFellowship(this.user);
    this.fellowshipService.getFellowshipOth(this.user);
    this.fellowshipSubscription = this.fellowshipService.fellowshipChanged.subscribe(fellowship =>{
      if(fellowship.status === 'fail'){
        this.snackbar.open('Error inserting record','',{duration: 3000, panelClass: ['snackbar-error']});
      } else {
        this.fellowship = fellowship.data;
        if (this.saving) {
          this.clearForm('fe');
          var sb = document.getElementById("save") as HTMLButtonElement;
          sb.disabled = false;
          this.snackbar.open('Entry added', '', { duration: 2000, panelClass: ['snackbar-success'] });
        }
      }
    });
    this.fellowshipOthSubscription = this.fellowshipService.fellowshipOthChanged.subscribe(fellowship =>{
      if(fellowship.status === 'fail'){
        this.snackbar.open('Error inserting record','',{duration: 3000, panelClass: ['snackbar-error']});
      } else {
        this.fellowshipOth = fellowship.data;
        this.fundingList = this.fellowshipOth.filter(record => record.type === "f");
        this.awardList = this.fellowshipOth.filter(record => record.type === "a");
        this.pubList = this.fellowshipOth.filter(record => record.type === "p");
        this.presentList = this.fellowshipOth.filter(record => record.type === "r");
        this.volunteerList = this.fellowshipOth.filter(record => record.type === "v");
        if (this.saving) {
          this.clearForm(this.fellowshipOth[this.fellowshipOth.length -1].type);
          var sb = document.getElementById("save") as HTMLButtonElement;
          sb.disabled = false;
          this.snackbar.open('Entry added', '', { duration: 2000, panelClass: ['snackbar-success'] });
        }
      }
    });
    this.deleteSubscription = this.deleteService.valueChanged.subscribe(fellowship =>{
      if(fellowship != null){
        if(fellowship.data.length > 0 && fellowship.data[0].hasOwnProperty('type')){
          this.fellowshipOth = fellowship.data;
          this.fundingList = this.fellowshipOth.filter(record => record.type === "f");
          this.awardList = this.fellowshipOth.filter(record => record.type === "a");
          this.pubList = this.fellowshipOth.filter(record => record.type === "p");
          this.presentList = this.fellowshipOth.filter(record => record.type === "r");
          this.volunteerList = this.fellowshipOth.filter(record => record.type === "v");
        }else if(fellowship.data.length > 0 && !fellowship.data[0].hasOwnProperty('type')){
          this.fellowship = fellowship.data;
        }else{
          this.fellowship = fellowship.data;
          this.fellowshipOth = fellowship.data;
        }
        this.snackbar.open('Entry Deleted','',{duration: 2000,panelClass: ['snackbar-success']});
      }
    });
    this.documentSubscription = this.addDocumentService.statusChanged.subscribe(status => {
      if(status.status == "success"){
        this.snackbar.open('Document added to Record','',{duration: 2000,panelClass: ['snackbar-success']});
        this.fellowshipService.getFellowship(this.user);
      }else{
        this.snackbar.open('Error adding Document to Record','',{duration: 2000,panelClass: ['snackbar-error']});
      }
    });
    this.picklistSubscription = this.picklistService.statesChanged.subscribe(states => {
      this.picklist = states.data;
    });
  }
  ngOnDestroy(){
    this.fellowshipSubscription.unsubscribe();
    this.fellowshipOthSubscription.unsubscribe();
    this.deleteSubscription.unsubscribe();
    this.documentSubscription.unsubscribe();
    this.picklistSubscription.unsubscribe();
  }
  public openDialog(file: any){
    var dialogRef = this.dialog.open(FileViewDialogComponent,{
      width: "1024px",
      data: file
    });
  }
  public openAddDocDialog(record: any, type: string){
    var data = {"id": record.id,"type": type};
    var dialogRef = this.dialog.open(AddDocumentDialogComponent,{
      width: "500px",
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined && result != null){
        this.addDocumentService.addDocument(this.user,result);
        this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
      }
    });
  }
  public onFileChange(event,fileInput: string) {
    const file = event.target.files[0];
    var check = this.utilService.checkFileExtention(file);
    if(check){
      var data = null;
      switch(fileInput){
        case 'file-fund':
          data = this.funding;
          break;
        case 'file-award':
          data = this.award;
          break;
        case 'file-pub':
          data = this.pub;
          break;
        case 'file-pre':
          data = this.present;
          break;
        case 'file-vol':
          data = this.volunteer;
          break;
        case 'file':
          data = this.data;
          break;
        default:
          data = this.data;
      }
      data.patchValue({
        fileSource: file
      });
    }else{
      var myFile = document.getElementById(fileInput) as HTMLInputElement;
      myFile.value = '';
      this.snackbar.open('File Type Not Accepted','',{duration: 2000,panelClass: ['snackbar-error']});
    }
  }
  public addFellowship(){
    if(this.data.status == "INVALID"){
      this.snackbar.open('Please ensure all fields have been completed.','',{duration: 2000,panelClass: ['snackbar-error']});
    }else{
      var formData = new FormData();
      formData.append("yearB", this.utilService.formatDate(this.data.value.date));
      formData.append("yearE", this.utilService.formatDate(this.data.value.exDate));
      formData.append("city", this.data.value.city);
      formData.append("state", this.data.value.state);
      formData.append("zipcode", this.data.value.zipcode);
      formData.append("name", this.data.value.name);
      formData.append("desc", this.data.value.description);
      formData.append("fileSource", this.data.value.fileSource);
      this.fellowshipService.insertFellowship(this.user, formData);
      this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
      this.saving = true;
      var sb = document.getElementById("save") as HTMLButtonElement;
      sb.disabled = true;
    }
  }
  public addOther(type: any){
    var data = null;
    switch(type){
      case 'f':
        data = this.funding;
        break;
      case 'a':
        data = this.award;
        break;
      case 'p':
        data = this.pub;
        break;
      case 'r':
        data = this.present;
        break;
      case 'v':
        data = this.volunteer;
        break;
    }
    if(data.status == "INVALID"){
      this.snackbar.open('Please ensure all fields have been completed.','',{duration: 2000,panelClass: ['snackbar-error']});
    }else{
      var formData = new FormData();
      if(type == 'f'){
        if(data.value.type == "Other"){
          formData.append("name", data.value.ftype);
        }else{
          formData.append("name", data.value.type);
        }
      }
      formData.append("type", type);
      formData.append("desc", data.value.description);
      this.fellowshipService.insertFellowshipOth(this.user, formData);
      this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
      this.saving = true;
      var sb = document.getElementById("save") as HTMLButtonElement;
      sb.disabled = true;
    }
  }
  public openEditDialog(record: any){
    var dialogRef = this.dialog.open(UpdateFellowshipDialogComponent,{
      width: "500px",
      data: record
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined && result != null){
        this.fellowshipService.updateFellowship(this.user, result);
        this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
        this.saving = true;
      }
    });
  }
  public openEditOthDialog(record: any){
    var dialogRef = this.dialog.open(UpdateOthFellowshipDialogComponent,{
      width: "500px",
      data: record
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined && result != null){
        this.fellowshipService.updateFellowshipOth(this.user, result);
        this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
        this.saving = true;
      }
    });
  }
  public deleteFellowship(id: string, type: string){
    let del = confirm("Are you sure?");
    if(del){
      if(type == 'f'){
        this.deleteService.deleteEntry(this.user,'T_FELLOW',id);
      }else{
        this.deleteService.deleteEntry(this.user,'T_FELLOW_OTH',id);
      }
    }
  }
  public resetForm(type: string){
    this.clearForm(type);
  }
  private clearForm(type: string){
    var data = null;
    var file = document.getElementById('file') as HTMLInputElement;
    switch(type){
      case 'f':
        data = this.funding;
        break;
      case 'a':
        data = this.award;
        break;
      case 'p':
        data = this.pub;
        break;
      case 'r':
        data = this.present;
        break;
      case 'v':
        data = this.volunteer;
        break;
      default:
        data = this.data;
        file.value = '';
    }
    data.reset();
  }
}
