import { Component, OnInit } from '@angular/core';
import { EducationService } from 'src/app/api/education.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/api/user.service';
import { Subscription } from 'rxjs';
import { PicklistService } from 'src/app/api/picklist.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { FileViewDialogComponent } from '../file-view-dialog/file-view-dialog.component';
import { DeleteService } from 'src/app/api/delete.service';
import { UpdateEducationDialogComponent } from './update-education-dialog/update-education-dialog.component';
import { UtilService } from 'src/app/api/util.service';
import { AddDocumentService } from 'src/app/api/add-document.service';
import { AddDocumentDialogComponent } from '../add-document-dialog/add-document-dialog.component';

@Component({
  selector: 'cup-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.css']
})
export class EducationComponent implements OnInit {
  private user: any;
  public data: any;
  public underGrad: any[];
  public grad: any[];
  public postGrad: any[];
  public internships: any[];
  public residencies: any[];
  public degreeLabel = "Degree Obtained";
  public degreeDescription = "Degree";
  public addressDescription = "University";
  private educationSubscription: Subscription;
  private picklistSubscription: Subscription;
  private documentSubscription: Subscription;
  private deleteSubscription: Subscription;
  public picklist;
  private saving = false;

  constructor(public fbuilder: FormBuilder, private userService: UserService,private utilService: UtilService, private educationService: EducationService,
    public picklistService: PicklistService,private deleteService: DeleteService,private addDocumentService: AddDocumentService,public dialog: MatDialog,public snackbar: MatSnackBar) {
    this.data = this.fbuilder.group({
      name: new FormControl(''),
      address: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      zip: new FormControl(''),
      from: new FormControl(''),  
      to: new FormControl(''),
      degree: new FormControl(''),
      type: new FormControl(''),
      description: new FormControl(),
      fileSource: new FormControl()
    });
   }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.educationService.getEducation(this.user);
    this.picklistService.getStates();
    this.educationSubscription = this.educationService.educationChanged.subscribe(education =>{
      if(education.status === 'fail'){
        this.snackbar.open('Error inserting record','',{duration: 3000, panelClass: ['snackbar-error']});
      } else {
        this.underGrad = education.data.filter(ug => ug.type === "U");
        this.grad = education.data.filter(g => g.type === "G");
        this.postGrad = education.data.filter(pg => pg.type === "P");
        this.internships = education.data.filter(pg => pg.type === "I");
        this.residencies = education.data.filter(pg => pg.type === "R");
        if (this.saving) {
          this.clearForm();
          var sb = document.getElementById("save") as HTMLButtonElement;
          sb.disabled = false;
          this.snackbar.open('Education Entry added', '', { duration: 2000, panelClass: ['snackbar-success'] });
        }
      }
    });
    this.picklistSubscription = this.picklistService.statesChanged.subscribe(states => {
      this.picklist = states.data;
    });
    this.documentSubscription = this.addDocumentService.statusChanged.subscribe(status => {
      if(status.status == "success"){
        this.snackbar.open('Document added to Record','',{duration: 2000,panelClass: ['snackbar-success']});
        this.educationService.getEducation(this.user);
      }else{
        this.snackbar.open('Error adding Document to Record','',{duration: 2000,panelClass: ['snackbar-error']});
      }
    });
    this.deleteSubscription = this.deleteService.valueChanged.subscribe(education =>{
      if(education!= null){
        this.underGrad = education.data.filter(ug => ug.type === "U");
        this.grad = education.data.filter(g => g.type === "G");
        this.postGrad = education.data.filter(pg => pg.type === "P");
        this.snackbar.open('Education Entry Deleted','',{duration: 2000,panelClass: ['snackbar-success']});
      }
    });
  }
  ngOnDestroy(){
    this.educationSubscription.unsubscribe();
    this.picklistSubscription.unsubscribe();
    this.deleteSubscription.unsubscribe();
    this.documentSubscription.unsubscribe();
  }
  public onFileChange(event) {
    const file = event.target.files[0];
    var check = this.utilService.checkFileExtention(file);
    if(check){
      this.data.patchValue({
        fileSource: file
      });
    }else{
      var myFile = document.getElementById('file') as HTMLInputElement;
      myFile.value = '';
      this.snackbar.open('File Type Not Accepted','',{duration: 2000,panelClass: ['snackbar-error']});
    }
  }
  public openDialog(file: any){
    var dialogRef = this.dialog.open(FileViewDialogComponent,{
      width: "1024px",
      data: file
    });
  }
  public addEducation(){
    if(this.data.status == "INVALID"){
      alert("Please ensure all fields have been completed.");
    }else{
      var formData = new FormData();
      formData.append("type", this.data.value.type);
      formData.append("name", this.data.value.name);
      formData.append("addr", this.data.value.address);
      formData.append("city", this.data.value.city);
      formData.append("state", this.data.value.state);
      formData.append("zip", this.data.value.zip);
      formData.append("yearB", this.data.value.from);
      formData.append("yearE", this.data.value.to);
      formData.append("degree", this.data.value.degree);
      formData.append("desc", this.data.value.description);
      formData.append("fileSource", this.data.value.fileSource);
      this.educationService.insertEducation(this.user, formData);
      this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
      this.saving = true;
      var sb = document.getElementById("save") as HTMLButtonElement;
      sb.disabled = true;
    }
  }
  public openEditDialog(record: any){
    var dialogRef = this.dialog.open(UpdateEducationDialogComponent,{
      width: "500px",
      data: record
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined && result != null){
        this.educationService.updateEducation(this.user, result);
        this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
        this.saving = true;
      }
    });
  }
  public openAddDocDialog(record: any){
    var data = {"id": record.id,"type": "T_EDU"};
    var dialogRef = this.dialog.open(AddDocumentDialogComponent,{
      width: "500px",
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined && result != null){
        this.addDocumentService.addDocument(this.user,result);
        this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
      }
    });
  }
  public deleteEducation(id: string){
    let del = confirm("Are you sure?");
    if(del){
      this.deleteService.deleteEntry(this.user,'T_EDU',id);
    }
  }
  public resetForm(){
    this.clearForm();
  }
  private clearForm(){
    this.data.reset();
    var file = document.getElementById('file') as HTMLInputElement;
    file.value = '';
  }
  public checkLabel(){
    if(this.data.value.type == "I" || this.data.value.type == "R"){
      this.degreeLabel = "Specialty";
      this.degreeDescription = "Specialty";
      this.addressDescription = "";
    }else{
      this.degreeLabel = "Degree Obtained";
      this.degreeDescription = "Degree";
      this.addressDescription = "University";
    }
  }
}
