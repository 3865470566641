import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class InsuranceService {
  public insurance: any;
  public insuranceChanged = new Subject<any>();
  constructor(private http: HttpClient) { }

  public insertInsurance(userId:String, data: any){
    this.http.post(`/rest/insertCOI.php?user=${userId}`,data).subscribe(status => {
      this.insurance = status;
      this.insuranceChanged.next(this.insurance);
    });
  }
  public updateInsurance(userId:String, data: any){
    this.http.post(`/rest/updateCOI.php?user=${userId}`,data).subscribe(status => {
      this.insurance = status;
      this.insuranceChanged.next(this.insurance);
    });
  }
  public getInsurance(userId: String){
    this.http.get(`/rest/getCOIs.php?user=${userId}`).subscribe(data => {
      this.insurance = data;
      this.insuranceChanged.next(this.insurance);
    });
  }
}
