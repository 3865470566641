import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReferencesService {
  public references: any;
  public referencesChanged = new Subject<any>();

  constructor(private http: HttpClient) { }
  public getReferences(userId: String){
    this.http.get(`/rest/getReferences.php?user=${userId}`).subscribe(data => {
      this.references = data;
      this.referencesChanged.next(this.references);
    });
  }
  public addReference(user: any, data: any){
    this.http.post(`/rest/insertReferences.php?user=${user}`,data).subscribe(result => {
      this.references = result;
      this.referencesChanged.next(this.references);
    });
  }
  public updateReference(user: any, data: any){
    this.http.post(`/rest/updateReferences.php?user=${user}`,data).subscribe(result => {
      this.references = result;
      this.referencesChanged.next(this.references);
    });
  }
}
