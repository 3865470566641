import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AnonymousSubject, Subject } from 'rxjs/internal/Subject';

const httpHeaders = {
  headers: new HttpHeaders({
    "Content-Type": "application/json"
  })
}

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  public profile: any;
  public profileChanged = new Subject<any>();
  constructor(private http: HttpClient) { }

  public getProfile(userId:String){
    this.http.get(`/rest/getProfiles.php?user=${userId}`).subscribe(profile => {
      this.profile = profile;
      this.profileChanged.next(this.profile);
    });
  }
  public updateProfile(userId:String, profile:any){
    this.http.post(`/rest/updateProfile.php?user=${userId}`,profile).subscribe(profile => {
      this.profile = profile;
      this.profileChanged.next(this.profile);
    });
  }
}
