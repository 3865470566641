import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { InsuranceService } from 'src/app/api/insurance.service';
import { UserService } from 'src/app/api/user.service';
import { Subscription } from 'rxjs';
import { FileViewDialogComponent } from '../file-view-dialog/file-view-dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DeleteService } from 'src/app/api/delete.service';
import { UpdateInsuranceDialogComponent } from './update-insurance-dialog/update-insurance-dialog.component';
import { UtilService } from 'src/app/api/util.service';

@Component({
  selector: 'cup-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.css']
})
export class InsuranceComponent implements OnInit {
  public picker: any;
  public expicker: any;
  public data: any;
  private insuranceSubscrption: Subscription;
  private deleteSubscription: Subscription;
  private user;
  public cois: any[] = [];
  public plis: any[] = [];
  public claims: any[] =[];
  private saving = false;

  constructor(public fbuilder: FormBuilder, private userService: UserService, private insuranceService: InsuranceService,private deleteService: DeleteService,
    public dialog: MatDialog,public snackbar: MatSnackBar,private utilService: UtilService) {
    this.data = this.fbuilder.group({
      date: new FormControl(''),
      exDate: new FormControl(''),
      type: new FormControl(''),
      desc: new FormControl(''),
      fileSource: new FormControl()
    });
  }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.insuranceService.getInsurance(this.user);
    this.insuranceSubscrption = this.insuranceService.insuranceChanged.subscribe(insurance => {
      if(insurance.status === 'fail'){
        this.snackbar.open('Error inserting record','',{duration: 3000, panelClass: ['snackbar-error']});
      }else{
        this.cois = insurance.data.filter(coi => coi.type === "C");
        this.plis = insurance.data.filter(pli => pli.type === "P");
        this.claims = insurance.data.filter(claim => claim.type === "I");
        if (this.saving) {
          this.clearForm();
          var sb = document.getElementById("save") as HTMLButtonElement;
          sb.disabled = false;
          this.snackbar.open('Insurance Entry added', '', { duration: 2000, panelClass: ['snackbar-success'] });
        }
      }
    });
    this.deleteSubscription = this.deleteService.valueChanged.subscribe(insurance =>{
      if(insurance!= null){
        this.cois = insurance.data.filter(coi => coi.type === "C");
        this.plis = insurance.data.filter(pli => pli.type === "P");
        this.claims = insurance.data.filter(claim => claim.type === "I");
        this.snackbar.open('Insurance Entry Deleted','',{duration: 2000,panelClass: ['snackbar-success']});
      }
    });
  }
  ngOnDestroy(){
    this.insuranceSubscrption.unsubscribe();
    this.deleteSubscription.unsubscribe();
  }
  public openDialog(file: any){
    var dialogRef = this.dialog.open(FileViewDialogComponent,{
      width: "1024px",
      data: file
    });
  }
  public onFileChange(event) {
    const file = event.target.files[0];
    var check = this.utilService.checkFileExtention(file);
    if(check){
      this.data.patchValue({
        fileSource: file
      });
    }else{
      var myFile = document.getElementById('file') as HTMLInputElement;
      myFile.value = '';
      this.snackbar.open('File Type Not Accepted','',{duration: 2000,panelClass: ['snackbar-error']});
    }
  }
  public addInsurance(){
    if(this.data.status == "INVALID"){
      this.snackbar.open('Please ensure all fields have been completed.','',{duration: 2000,panelClass: ['snackbar-error']});
    }else{
      if(this.data.value.fileSource != null){
        var formData = new FormData();
        formData.append("dateV", this.utilService.formatDate(this.data.value.date));
        formData.append("dateE", this.utilService.formatDate(this.data.value.exDate));
        formData.append("type", this.data.value.type);
        formData.append("desc", this.data.value.desc);
        formData.append("fileSource", this.data.value.fileSource);
        this.insuranceService.insertInsurance(this.user, formData);
        this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
        this.saving = true;
        var sb = document.getElementById("save") as HTMLButtonElement;
        sb.disabled = true;
        this.clearForm();
      }else{
        this.snackbar.open('File Required','',{duration: 2000,panelClass: ['snackbar-error']});
      }
    }
  }
  public openEditDialog(record: any){
    var dialogRef = this.dialog.open(UpdateInsuranceDialogComponent,{
      width: "500px",
      data: record
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined && result != null){
        this.insuranceService.updateInsurance(this.user, result);
        this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
        this.saving = true;
      }
    });
  }
  public deleteInsurance(id: string){
    let del = confirm("Are you sure?");
    if(del){
      this.deleteService.deleteEntry(this.user,'T_COI',id);
    }
  }
  public resetForm(){
    this.clearForm();
  }
  private clearForm(){
    var file = document.getElementById('file') as HTMLInputElement;
    file.value = '';
    this.data.reset();
  }
}