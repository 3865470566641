import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CaselogsService {
  public caselog: any;
  public caselogChanged = new Subject<any>();

  constructor(private http: HttpClient) { }
  public getCaselog(userId: String){
    this.http.get(`/rest/getCases.php?user=${userId}`).subscribe(data => {
      this.caselog = data;
      this.caselogChanged.next(this.caselog);
    });
  }
  public addCaselog(user: any, data: any){
    this.http.post(`/rest/insertCases.php?user=${user}`,data).subscribe(result => {
      this.caselog = result;
      this.caselogChanged.next(this.caselog);
    });
  }
  public updateCaselog(user: any, data: any){
    this.http.post(`/rest/updateCases.php?user=${user}`,data).subscribe(result => {
      this.caselog = result;
      this.caselogChanged.next(this.caselog);
    });
  }
}
