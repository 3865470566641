import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/api/user.service';
import { OtherUploadsService } from 'src/app/api/other-uploads.service';
import { ProfileService } from 'src/app/api/profile.service';
import { PhysicalService } from 'src/app/api/physical.service';
import { ImmunizationsService } from 'src/app/api/immunizations.service';
import { InsuranceService } from 'src/app/api/insurance.service';
import { EducationService } from 'src/app/api/education.service';
import { FellowshipService } from 'src/app/api/fellowship.service';
import { LicenseService } from 'src/app/api/license.service';
import { CertificationsService } from 'src/app/api/certifications.service';
import { WorkHistoryService } from 'src/app/api/work-history.service';
import { ContinuingEducationService } from 'src/app/api/continuing-education.service';
import { CaselogsService } from 'src/app/api/caselogs.service';
import { ReferencesService } from 'src/app/api/references.service';
import { Subscription } from 'rxjs';
import { CreatelinkService } from 'src/app/api/createlink.service';
import { MatExpansionPanel, MatExpansionPanelHeader, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { FileViewDialogComponent } from '../file-view-dialog/file-view-dialog.component';
import { jsPDF } from "jspdf";
import { LegalLinkDialogComponent } from './legal-link-dialog/legal-link-dialog.component';

@Component({
  selector: 'cup-create-link',
  templateUrl: './create-link.component.html',
  styleUrls: ['./create-link.component.css']
})
export class CreateLinkComponent implements OnInit {
  @ViewChild('profilePanel', {static: true}) profilePanel: MatExpansionPanelHeader;
  @ViewChild('attachmentsPanel', {static: true}) attachmentsPanel: MatExpansionPanelHeader;
  public data: any;
  private profileSub: Subscription;
  private physicalSub: Subscription;
  private immunizationSub: Subscription;
  private insuranceSub: Subscription;
  private educationSub: Subscription;
  private fellowshipSub: Subscription;
  private fellowshipOthSub: Subscription;
  private licenseSub: Subscription;
  private certificationSub: Subscription;
  private workHistorySub: Subscription;
  private qualificationSub: Subscription;
  private contiuningEducationSub: Subscription;
  private caselogSub: Subscription;
  private referencesSub: Subscription;
  private otherUploadsSub: Subscription;
  private user: any;
  public title = "";
  public linkDuration: string;
  public durations: string[] = ['24','48','72'];
  public values: string[] = ['Yes','No'];
  public profile: any;
  public paypal: any;
  public physicals: any[] = [];
  public labs: any[] = [];
  public fits: any[] = [];
  public titers: any[] = [];
  public flu: any[] = [];
  public ppd: any[] = [];
  public other: any[] = [];
  public covidr: any[] = [];
  public covidat: any[] = [];
  public covidav: any[] = [];
  public cois: any[] = [];
  public plis: any[] = [];
  public claims: any[] = [];
  public underGrad: any[] = [];
  public grad: any[] = [];
  public postGrad: any[] = [];
  public internship: any[] = [];
  public residency: any[] = [];
  public underGradD: any[] = [];
  public gradD: any[] = [];
  public postGradD: any[] = [];
  public internshipD: any[] = [];
  public residencyD: any[] = [];
  public fellowships: any[] = [];
  public fellowshipsD: any[] = [];
  public fellowshipsOth: any[] = [];
  public fundingType: string [] = ['Scholarships','Fellowship Funding','Research Funding','Other'];
  public scholarships: any[];
  public fellowshipFunding: any[];
  public researchFunding: any[];
  public otherFunding: any[];
  public fundingList: any[] = [];
  public awardList: any[] = [];
  public pubList: any[] = [];
  public presentList: any[] = [];
  public volunteerList: any[] = [];
  public fundingListD: any[] = [];
  public awardListD: any[] = [];
  public pubListD: any[] = [];
  public presentListD: any[] = [];
  public volunteerListD: any[] = [];
  public licenses: any[] = [];
  public deaLicenses: any[] = [];
  public licensesD: any[] = [];
  public deaLicensesD: any[] = [];
  public alcsList: any[] = [];
  public palsList: any[] = [];
  public blsList: any[] = [];
  public nrpList: any[] = [];
  public traumaList: any[] = [];
  public otherList: any[] = [];
  public membershipList: any[] = [];
  public alcsListD: any[] = [];
  public palsListD: any[] = [];
  public blsListD: any[] = [];
  public nrpListD: any[] = [];
  public traumaListD: any[] = [];
  public otherListD: any[] = [];
  public membershipListD: any[] = [];
  public workHistory: any[] = [];
  public qualifications: any[] = [];
  public profQualifications: any[] = [];
  public continuingEducation: any[] = [];
  public caselogs: any[] = [];
  public references: any[] = [];
  public referencesD: any[] = [];
  public otherUploads: any[] = [];

  public physicalsFull: any[] = [];
  public immunizationsFull: any[] = [];
  public insuranceFull: any[] = [];
  public educationFull: any[] = [];
  public licensesFull: any[] = [];
  public certsFull: any[] = [];
  public profilePicture: any = null;
  public profilePhoto = false;

  private profileComplete = false;
  private attachmentsComplete = false;
  private markedComplete = false;
  private resumePhysicals = [];
  private resumeImmunizations = [];
  private resumeInsurance = [];
  private resumeEducation = [];
  private resumeFellowship = [];
  private resumeFellowshipOth = [];
  private resumeLicenses = [];
  private resumeCerts = [];
  private resumeContEdu = [];
  private resumeCaseLog = [];
  private resumeReferences = [];
  private resumeOther = [];

  private attachments = [];

  constructor(public fbuilder: FormBuilder, private userService: UserService,
    private profileService: ProfileService,
    private physicalService: PhysicalService,
    private immunizationsService: ImmunizationsService,
    private insuranceService: InsuranceService,
    private educationService: EducationService,
    private fellowshipService: FellowshipService,
    private licenseService: LicenseService,
    private certificationService: CertificationsService,
    private workHistoryService: WorkHistoryService,
    private continuingEducationService: ContinuingEducationService,
    private caselogsService: CaselogsService,
    private referencesService: ReferencesService,
    private otherUploadsService: OtherUploadsService,
    private ngZone: NgZone,
    private createLinkService: CreatelinkService,
    public router: Router,
    public dialog: MatDialog) { }

  ngOnInit() {
    window['angularComponentReference'] = { component: this, zone: this.ngZone, loadAngularFunction: (details) => this.completeSendLink(details), }; 
    this.data = this.fbuilder.group({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, 
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      emailConfirm: new FormControl('', [Validators.required, 
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      duration: new FormControl('', Validators.required),
      includeCV: new FormControl('', Validators.required)
    });
    this.user = this.userService.getUser();
    this.title = this.userService.getTitle();
    this.profileService.getProfile(this.user);
    this.physicalService.getPhysicals(this.user);
    this.immunizationsService.getImmunizations(this.user);
    this.insuranceService.getInsurance(this.user);
    this.educationService.getEducation(this.user);
    this.fellowshipService.getFellowship(this.user);
    this.fellowshipService.getFellowshipOth(this.user);
    this.licenseService.getLicense(this.user);
    this.certificationService.getCertifications(this.user);
    this.workHistoryService.getWorkHistory(this.user);
    this.workHistoryService.getQualifications(this.user);
    this.continuingEducationService.getContinuingEducation(this.user);
    this.caselogsService.getCaselog(this.user);
    this.referencesService.getReferences(this.user);
    this.otherUploadsService.getOtherUploads(this.user);
    this.profileSub = this.profileService.profileChanged.subscribe(profile => {
      this.profile = profile.data;
      if(this.profile.fileSource != ''){
        this.profilePhoto = true;
      }
    });
    this.physicalSub = this.physicalService.physicalsChanged.subscribe(physical => {
      this.physicalsFull = physical.data;
      this.physicals = physical.data.filter(type => type.type === "P");
      this.labs = physical.data.filter(type => type.type === "L");
      this.fits = physical.data.filter(type => type.type === "M");
    });
    this.immunizationSub = this.immunizationsService.immunizationsChanged.subscribe(immunizations => {
      this.immunizationsFull = immunizations.data;
      this.covidr = immunizations.data.filter(covid => covid.type === "C");
      this.covidat = immunizations.data.filter(covid => covid.type === "A");
      this.covidav = immunizations.data.filter(covid => covid.type === "V");
      this.titers = immunizations.data.filter(titer => titer.type === "T");
      this.flu = immunizations.data.filter(flu => flu.type === "F");
      this.ppd = immunizations.data.filter(ppd => ppd.type === "P");
      this.other = immunizations.data.filter(other => other.type === "O");
    });
    this.insuranceSub = this.insuranceService.insuranceChanged.subscribe(insurance => {
      this.insuranceFull = insurance.data;
      this.cois = insurance.data.filter(coi => coi.type === "C");
      this.plis = insurance.data.filter(pli => pli.type === "P");
      this.claims = insurance.data.filter(claim => claim.type === "I");
    });
    this.educationSub = this.educationService.educationChanged.subscribe(education => {
      this.educationFull = education.data;
      this.underGrad = education.data.filter(ug => ug.type === "U");
      this.grad = education.data.filter(g => g.type === "G");
      this.postGrad = education.data.filter(pg => pg.type === "P");
      //added in Internships and Residencies
      this.internship = education.data.filter(i => i.type === "I");
      this.residency = education.data.filter(r => r.type === "R");
      //filter out no documents
      this.underGradD = this.underGrad.filter(ug => ug.diploma != "data:image\/jpeg;base64,");
      this.gradD = this.grad.filter(g => g.diploma != "data:image\/jpeg;base64,");
      this.postGradD = this.postGrad.filter(pg => pg.diploma != "data:image\/jpeg;base64,");
      this.internshipD = this.internship.filter(i => i.diploma != "data:image\/jpeg;base64,");
      this.residencyD = this.residency.filter(r => r.diploma != "data:image\/jpeg;base64,");
    });
    this.fellowshipSub = this.fellowshipService.fellowshipChanged.subscribe(fellowships => {
      this.fellowships = fellowships.data;
      this.fellowshipsD = fellowships.data.filter(fellow => fellow.fFile != "data:image\/jpeg;base64,");
    });
    this.fellowshipOthSub = this.fellowshipService.fellowshipOthChanged.subscribe(fellowships => {
      this.fellowshipsOth = fellowships.data;
      this.fundingList = fellowships.data.filter(oth => oth.type === "f");
      this.fellowshipFunding = this.fundingList.filter(other => other.name === "Fellowship Funding");
      this.scholarships = this.fundingList.filter(other => other.name === "Scholarships");
      this.researchFunding = this.fundingList.filter(other => other.name === "Research Funding");
      this.otherFunding = this.fundingList.filter(other => {
          return !this.fundingType.find(fund => {
             return fund === other.name;
        })});
      this.awardList = fellowships.data.filter(record => record.type === "a");
      this.pubList = fellowships.data.filter(record => record.type === "p");
      this.presentList = fellowships.data.filter(record => record.type === "r");
      this.volunteerList = fellowships.data.filter(record => record.type === "v");
    });
    this.licenseSub = this.licenseService.licenseChanged.subscribe(licenses => {
      this.licensesFull = licenses.data;
      this.licenses = licenses.data.filter(type => type.type != "D");
      this.licensesD = this.licenses.filter(lic => lic.image != "data:image\/jpeg;base64,");
      this.deaLicenses = licenses.data.filter(type => type.type === "D");
      this.deaLicensesD = this.deaLicenses.filter(lic => lic.image != "data:image\/jpeg;base64,");
    });
    this.certificationSub = this.certificationService.certsChanged.subscribe(certs => {
      this.certsFull = certs.data;
      this.alcsList = certs.data.filter(alcs => alcs.type === "A");
      this.palsList = certs.data.filter(pals => pals.type === "P");
      this.blsList = certs.data.filter(bls => bls.type === "B");
      this.nrpList = certs.data.filter(nrp => nrp.type === "N");
      this.traumaList = certs.data.filter(trauma => trauma.type === "T");
      this.otherList = certs.data.filter(other => other.type === "O");
      this.membershipList = certs.data.filter(other => other.type === "M");
      //filter out the no document items
      this.alcsListD = this.alcsList.filter(alcs => alcs.image != "data:image\/jpeg;base64,");
      this.palsListD = this.palsList.filter(pals => pals.image != "data:image\/jpeg;base64,");
      this.blsListD = this.blsList.filter(bls => bls.image != "data:image\/jpeg;base64,");
      this.nrpListD = this.nrpList.filter(nrp => nrp.image != "data:image\/jpeg;base64,");
      this.traumaListD = this.traumaList.filter(trauma => trauma.image != "data:image\/jpeg;base64,");
      this.otherListD = this.otherList.filter(other => other.image != "data:image\/jpeg;base64,");
      this.membershipListD = this.membershipList.filter(other => other.image != "data:image\/jpeg;base64,");

    });
    this.workHistorySub = this.workHistoryService.workHistoryChanged.subscribe(workHistory => {
      this.workHistory = workHistory.data;
    });
    this.qualificationSub = this.workHistoryService.qualificationsChanged.subscribe(qual => {
      this.qualifications = qual.data.filter(qual => qual.type === "S");
      this.profQualifications = qual.data.filter(qual => qual.type === "P");
    });
    this.contiuningEducationSub = this.continuingEducationService.ceChanged.subscribe(ce => {
      this.continuingEducation = ce.data;
    });
    this.caselogSub = this.caselogsService.caselogChanged.subscribe(caselogs => {
      this.caselogs = caselogs.data;
    });
    this.referencesSub = this.referencesService.referencesChanged.subscribe(references => {
      this.references = references.data;
      this.referencesD = references.data.filter(ref => ref.letter != "data:image\/jpeg;base64,");
    });
    this.otherUploadsSub = this.otherUploadsService.otherUploadsChanged.subscribe(otherUploads => {
      this.otherUploads = otherUploads.data;
    });
  }
  public openDialog(file: any){
    var dialogRef = this.dialog.open(FileViewDialogComponent,{
      width: "1024px",
      data: file
    });
  }
  ngOnDestroy(){
    this.profileSub.unsubscribe();
    this.physicalSub.unsubscribe();
    this.immunizationSub.unsubscribe();
    this.insuranceSub.unsubscribe();
    this.educationSub.unsubscribe();
    this.fellowshipSub.unsubscribe();
    this.fellowshipOthSub.unsubscribe();
    this.licenseSub.unsubscribe();
    this.certificationSub.unsubscribe();
    this.workHistorySub.unsubscribe();
    this.qualificationSub.unsubscribe();
    this.contiuningEducationSub.unsubscribe();
    this.caselogSub.unsubscribe();
    this.referencesSub.unsubscribe();
    this.otherUploadsSub.unsubscribe();
  }
  public checkCheck(type,item,$event){
    if(type === "profile"){
      if($event.target.checked){
        this.profilePicture = item;
      }else{
        this.profilePicture = null;
      }
    }
    if(type === "physical"){
      if($event.target.checked){
        this.resumePhysicals.push(item);
      }else{
        var index = this.resumePhysicals.indexOf(item);
        this.resumePhysicals.splice(index,1);
      }
    }
    if(type === "immunization"){
      if($event.target.checked){
        this.resumeImmunizations.push(item);
      }else{
        var index = this.resumeImmunizations.indexOf(item);
        this.resumeImmunizations.splice(index,1);
      }
    }
    if(type === "insurance"){
      if($event.target.checked){
        this.resumeInsurance.push(item);
      }else{
        var index = this.resumeInsurance.indexOf(item);
        this.resumeInsurance.splice(index,1);
      }
    }
    if(type === "education"){
      if($event.target.checked){
        this.resumeEducation.push(item);
      }else{
        var index = this.resumeEducation.indexOf(item);
        this.resumeEducation.splice(index,1);
      }
    }
    if(type === "fellowship"){
      if($event.target.checked){
        this.resumeFellowship.push(item);
      }else{
        var index = this.resumeFellowship.indexOf(item);
        this.resumeFellowship.splice(index,1);
      }
    }
    if(type === "fellowshipOth"){
      if($event.target.checked){
        this.resumeFellowshipOth.push(item);
      }else{
        var index = this.resumeFellowshipOth.indexOf(item);
        this.resumeFellowshipOth.splice(index,1);
      }
    }
    if(type === "license"){
      if($event.target.checked){
        this.resumeLicenses.push(item);
      }else{
        var index = this.resumeLicenses.indexOf(item);
        this.resumeLicenses.splice(index,1);
      }
    }
    if(type === "certs"){
      if($event.target.checked){
        this.resumeCerts.push(item);
      }else{
        var index = this.resumeCerts.indexOf(item);
        this.resumeCerts.splice(index,1);
      }
    }
    if(type === "caseLog"){
      if($event.target.checked){
        this.resumeCaseLog.push(item);
      }else{
        var index = this.resumeCaseLog.indexOf(item);
        this.resumeCaseLog.splice(index,1);
      }
    }
    if(type === "contEdu"){
      if($event.target.checked){
        this.resumeContEdu.push(item);
      }else{
        var index = this.resumeContEdu.indexOf(item);
        this.resumeContEdu.splice(index,1);
      }
    }
    if(type === "references"){
      if($event.target.checked){
        this.resumeReferences.push(item);
      }else{
        var index = this.resumeReferences.indexOf(item);
        this.resumeReferences.splice(index,1);
      }
    }
    if(type === "other"){
      if($event.target.checked){
        this.resumeOther.push(item);
      }else{
        var index = this.resumeOther.indexOf(item);
        this.resumeOther.splice(index,1);
      }
    }
    this.checkComplete();
  }
  public markComplete(type: any){
    if(type === "profile"){
      var profileId = this.profilePanel.panel._headerId; 
      var profile = document.getElementById(profileId);
      profile.style.backgroundColor = "#00FF00";
      this.profileComplete = true;
    }
    if(type === "attachments"){
      var profileId = this.attachmentsPanel.panel._headerId; 
      var profile = document.getElementById(profileId);
      profile.style.backgroundColor = "#00FF00";
      this.attachmentsComplete = true;
    }
    
    this.checkComplete();
  }
  private checkComplete(){
    if(this.profileComplete && this.attachmentsComplete){
      this.markedComplete = true;
    }
  }
  public sendLink(){
    if(this.data.status == "INVALID"){
      alert("Please ensure all fields have been completed.");
    }else{
      if(this.data.value.duration == "24" || this.data.value.duration == "48" || this.data.value.duration == "72"){
        if(this.data.value.email.toLowerCase() == this.data.value.emailConfirm.toLowerCase()){
          var dialogRef = this.dialog.open(LegalLinkDialogComponent,{
            width: "300px"
          });
          dialogRef.afterClosed().subscribe(result => {
            if(result){
              this.completeSendLink("");
            }
          });         
        }else{
          alert("Email addresses do not match.  Please verify email address");
        }
      }else{
         alert("No duration was chosen.  Link will not be sent without a duration.");
      }
    }
   
  }
  private completeSendLink(details){
    if(this.profilePicture != null){
      this.attachments.push(this.profilePicture);
    }
    if(this.resumePhysicals.length > 0){
      this.addToAttachments(this.physicalsFull, this.resumePhysicals);
    }
    if(this.resumeImmunizations.length > 0){
      this.addToAttachments(this.immunizationsFull, this.resumeImmunizations);
    }
    if(this.resumeInsurance.length > 0){
      this.addToAttachments(this.insuranceFull, this.resumeInsurance);
    }
    if(this.resumeEducation.length > 0){
      this.addToAttachments(this.educationFull, this.resumeEducation);
    }
    if(this.resumeFellowship.length > 0){
      this.addToAttachments(this.fellowships, this.resumeFellowship);
    }
    if(this.resumeFellowshipOth.length > 0){
      this.addToAttachments(this.fellowshipsOth, this.resumeFellowshipOth);
    }
    if(this.resumeLicenses.length > 0){
      this.addToAttachments(this.licensesFull, this.resumeLicenses);
    }
    if(this.resumeCerts.length > 0){
      this.addToAttachments(this.certsFull, this.resumeCerts);
    }
    if(this.resumeContEdu.length > 0){
      this.addToAttachments(this.continuingEducation, this.resumeContEdu);
    }
    if(this.resumeCaseLog.length > 0){
      this.addToAttachments(this.caselogs, this.resumeCaseLog);
    }
    if(this.resumeReferences.length > 0){
      this.addToAttachments(this.references, this.resumeReferences);
    }
    if(this.resumeOther.length > 0){
      this.addToAttachments(this.otherUploads, this.resumeOther);
    }
    var formData = new FormData();
    formData.append("name", this.data.value.name);
    formData.append("email", this.data.value.email.toLowerCase());
    formData.append("duration", this.data.value.duration);
    formData.append("cv", this.checkCV());
    formData.append("attachments", JSON.stringify(this.attachments));
    this.createLinkService.insertLinkPacket(this.user,formData);
    this.router.navigateByUrl('/user/confirmation');
  }
  private addToAttachments(fullList: any [], list: any[]){
    for (var i = 0; i < list.length; i++) {
      var row = fullList.filter(obj => obj.id == list[i])[0];
      if (row.pages == 1) {
        if(typeof row.file != 'undefined'){
          this.attachments.push(row.file);
        }else if(typeof row.file != 'undefined'){
          this.attachments.push(row.file);
        }else if(typeof row.file != 'undefined'){
          this.attachments.push(row.file);
        }else if(typeof row.file != 'undefined'){
          this.attachments.push(row.file);
        }else if(typeof row.file != 'undefined'){
          this.attachments.push(row.file);
        }else if(typeof row.file != 'undefined'){
          this.attachments.push(row.file);
        }else if(typeof row.file != 'undefined'){
          this.attachments.push(row.file);
        }else if(typeof row.file != 'undefined'){
          this.attachments.push(row.file);
        }
      } else {
        for (var k = 0; k < row.nFiles.length; k++) {
          this.attachments.push(row.nFiles[k]);
        }
      }
    }
  }
  private checkCV(){
    if(this.data.includeCV == "Yes"){
      return "Y";
    }
    if(this.data.includeCV == "No"){
      return "N";
    }
    return "E";
  }
}
