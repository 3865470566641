import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UtilService } from 'src/app/api/util.service';

@Component({
  selector: 'cup-update-cert-dialog',
  templateUrl: './update-cert-dialog.component.html',
  styleUrls: ['./update-cert-dialog.component.css']
})
export class UpdateCertDialogComponent implements OnInit {
  public record: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private utilService: UtilService, public dialogRef: MatDialogRef<UpdateCertDialogComponent>,public fbuilder: FormBuilder) { }

  ngOnInit() {
    this.record = this.fbuilder.group({
      date: new FormControl(new Date(this.data.i_Date)),
      exDate: new FormControl(new Date(this.data.e_Date)),
      type: new FormControl(this.data.type),
      specialty: new FormControl(this.data.spcl),
      agency: new FormControl(this.data.agency)
    });
  }
  public update(){
    if(this.data.status == "INVALID"){
      alert("Please ensure all fields have been completed.");
    }else{
      var formData = new FormData();
      formData.append("id", this.data.id);
      formData.append("i_Date", this.utilService.formatDate(this.record.value.date));
      formData.append("e_Date", this.utilService.formatDate(this.record.value.exDate));
      formData.append("type", this.record.value.type);
      formData.append("spcl", this.record.value.specialty);
      formData.append("agency", this.record.value.agency);
      this.dialogRef.close(formData);
    }
  }
  public cancel(){
    this.dialogRef.close(null);
  }
}
