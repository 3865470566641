import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/api/user.service';
import { CaselogsService } from 'src/app/api/caselogs.service';
import { FileViewDialogComponent } from '../file-view-dialog/file-view-dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DeleteService } from 'src/app/api/delete.service';
import { UpdateCaselogDialogComponent } from './update-caselog-dialog/update-caselog-dialog.component';
import { UtilService } from 'src/app/api/util.service';

@Component({
  selector: 'cup-caselogs',
  templateUrl: './caselogs.component.html',
  styleUrls: ['./caselogs.component.css']
})
export class CaselogsComponent implements OnInit {
  public data: any;
  private caselogSubscrption: Subscription;
  private deleteSubscription: Subscription;
  private user;
  public caselogs: any[] =[];
  private saving = false;

  constructor(public fbuilder: FormBuilder, private userService: UserService,private utilService: UtilService,private caselogsService: CaselogsService,private deleteService: DeleteService,
    public dialog: MatDialog, public snackbar: MatSnackBar) { 
    this.data = this.fbuilder.group({
      date: new FormControl('',Validators.required),
      exDate: new FormControl(''),
      description: new FormControl('',Validators.required),
      fileSource: new FormControl('')
    });
  }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.caselogsService.getCaselog(this.user);
    this.caselogSubscrption = this.caselogsService.caselogChanged.subscribe(caselog => {
      if(caselog.status === 'fail'){
        this.snackbar.open('Error inserting record','',{duration: 3000, panelClass: ['snackbar-error']});
      } else {
        this.caselogs = caselog.data;
        if (this.saving) {
          this.clearForm();
          var sb = document.getElementById("save") as HTMLButtonElement;
          sb.disabled = false;
          this.snackbar.open('Caselog Entry added', '', { duration: 2000, panelClass: ['snackbar-success'] });
        }
      }
    });
    this.deleteSubscription = this.deleteService.valueChanged.subscribe(caselog =>{
      if(caselog != null){
        this.caselogs = caselog.data;
        this.snackbar.open('Caselog Entry Deleted','',{duration: 2000,panelClass: ['snackbar-success']});
      }
    });
  }
  ngOnDestroy(){
    this.caselogSubscrption.unsubscribe();
    this.deleteSubscription.unsubscribe();
  }
  public openDialog(file: any){
    var dialogRef = this.dialog.open(FileViewDialogComponent,{
      width: "1024px",
      data: file
    });
  }
  public onFileChange(event) {
    const file = event.target.files[0];
    var check = this.utilService.checkFileExtention(file);
    if(check){
      this.data.patchValue({
        fileSource: file
      });
    }else{
      var myFile = document.getElementById('file') as HTMLInputElement;
      myFile.value = '';
      this.snackbar.open('File Type Not Accepted','',{duration: 2000,panelClass: ['snackbar-error']});
    }
  }
  public addCaselog(){
    if(this.data.status == "INVALID"){
      this.snackbar.open('Please ensure all fields have been completed.','',{duration: 2000,panelClass: ['snackbar-error']});
    }else{
      if(this.data.value.fileSource != null){
        var formData = new FormData();
        formData.append("dte", this.utilService.formatDate(this.data.value.date));
        formData.append("exDte", this.utilService.formatDate(this.data.value.exDate));
        formData.append("desc", this.data.value.description);
        formData.append("fileSource", this.data.value.fileSource);
        this.caselogsService.addCaselog(this.user, formData);
        this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
        this.saving = true;
        var sb = document.getElementById("save") as HTMLButtonElement;
        sb.disabled = true;
        this.clearForm();
      }else{
        this.snackbar.open('File Required','',{duration: 2000,panelClass: ['snackbar-error']});
      }
    }
  }
  public openEditDialog(record: any){
    var dialogRef = this.dialog.open(UpdateCaselogDialogComponent,{
      width: "500px",
      data: record
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined && result != null){
        this.caselogsService.updateCaselog(this.user, result);
        this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
        this.saving = true;
      }
    });
  }
  public deleteCaselog(id: string){
    let del = confirm("Are you sure?");
    if(del){
      this.deleteService.deleteEntry(this.user,'T_CASE',id);
    }
  }
  public resetForm(){
    this.clearForm();
  }
  private clearForm(){
    var file = document.getElementById('file') as HTMLInputElement;
    file.value = '';
    this.data.reset();
  }
}
