import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const httpHeaders = {
  headers: new HttpHeaders({
    "Content-Type": "application/json"
  })
}

@Injectable({
  providedIn: 'root'
})

export class UserService {
  public user: any;
  public userChanged = new Subject<any>();
  public username: any
  public usernameChanged = new Subject<any>();
  public emailExists: any;
  public emailExistsChanged = new Subject<any>();
  public userExists: any;
  public userExistsChanged = new Subject<any>();
  public assist: any;
  public assistChanged = new Subject<any>();

  constructor(public http: HttpClient) { }
  public getUser(){
    return this.user.user;
  }
  public getTitle(){
    return this.user.title;
  }
  public get(data: any){
    this.http.get(`/rest/signIn.php?user=${data.username}&pass=${data.password}`,httpHeaders).subscribe(result=>{
        this.user = result;
        this.userChanged.next(this.user);
      });
  }
  public checkUsername(data: any){
    this.http.get(`/rest/checkUsername.php?user=${data}`).subscribe(result=>{
        this.userExists = result;
        this.userExistsChanged.next(this.userExists);
      });
  }
  public checkEmail(data: any){
    this.http.get(`/rest/checkEmail.php?email=${data}`).subscribe(result=>{
        this.emailExists = result;
        this.emailExistsChanged.next(this.emailExists);
      });
  }
  public changePassword(userId: string,data: string){
    this.http.post(`/rest/changePassword.php?user=${userId}`, data).subscribe(status => {
      this.user = status;
      this.userChanged.next(this.user);
    });
  }
  public findUsername(data: any){
    this.http.post(`/rest/findUsername.php`, data).subscribe(status => {
      this.username = status;
      this.usernameChanged.next(this.username);
    });
  }
  public resetPassword(data: any){
    this.http.post(`/rest/resetPassword.php`, data).subscribe(status => {
      this.username = status;
      this.usernameChanged.next(this.username);
    });
  }
  public sendAssistance(userId: string,data: any){
    this.http.post(`/rest/sendAssistance.php?user=${userId}`, data).subscribe(status => {
      this.assist = status;
      this.assistChanged.next(this.assist);
    });
  }
}