import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddDocumentService {
  public status: any;
  public statusChanged = new Subject<any>();
  constructor(private http: HttpClient) { }
  public addDocument(userId:String, data: any){
    this.http.post(`/rest/addDocument.php?user=${userId}`,data).subscribe(status => {
      this.status = status;
      this.statusChanged.next(this.status);
    });
  }

}
