import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PhysicalService {
  public physicals: any;
  public physicalsChanged = new Subject<any>();
  constructor(private http: HttpClient) { }

  public insertPhysical(userId:String, data: any){
    this.http.post(`/rest/insertPhysExam.php?user=${userId}`,data).subscribe(status => {
      this.physicals = status;
      this.physicalsChanged.next(this.physicals);
    });
  }
  public updatePhysical(userId:String, data: any){
    this.http.post(`/rest/updatePhysExam.php?user=${userId}`,data).subscribe(status => {
      this.physicals = status;
      this.physicalsChanged.next(this.physicals);
    });
  }
  public getPhysicals(userId: String){
    this.http.get(`/rest/getPhysExams.php?user=${userId}`).subscribe(data => {
      this.physicals = data;
      this.physicalsChanged.next(this.physicals);
    });
  }
}
