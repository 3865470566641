import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UtilService } from 'src/app/api/util.service';

@Component({
  selector: 'cup-add-document-dialog',
  templateUrl: './add-document-dialog.component.html',
  styleUrls: ['./add-document-dialog.component.css']
})
export class AddDocumentDialogComponent implements OnInit {
  public record: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public fbuilder: FormBuilder,private utilService: UtilService, public dialogRef: MatDialogRef<AddDocumentDialogComponent>) { }

  ngOnInit() {
    this.record = this.fbuilder.group({
      id:  new FormControl(this.data.id, Validators.required),
      type:  new FormControl(this.data.type, Validators.required),
      fileSource: ''
    });
  }
  ngOnDestroy(){
    this.record.reset();
    var file = document.getElementById('file') as HTMLInputElement;
    file.value = '';
  }
  public onFileChange(event) {
    const file = event.target.files[0];
    var check = this.utilService.checkFileExtention(file);
    if(check){
      this.record.patchValue({
        fileSource: file
      });
    }else{
      var myFile = document.getElementById('file') as HTMLInputElement;
      myFile.value = '';
      alert('File Type Not Accpeted');
    }
  }
  public addDocument(){
    if(this.record.value.fileSource != null){
      var formData = new FormData();
      formData.append("id", this.record.value.id);
      formData.append("type", this.record.value.type);
      formData.append("fileSource", this.record.value.fileSource);
      this.dialogRef.close(formData);
    }else{
      alert('File Required');
    }
  }
  public cancel(){
    this.dialogRef.close(null);
  }
}
