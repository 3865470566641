import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PicklistService {
  public states: any;
  public statesChanged = new Subject<any>();
  
  constructor(private http: HttpClient) { }
  public getStates(){
    this.http.get(`/rest/getState.php`).subscribe(data => {
      this.states = data;
      this.statesChanged.next(this.states);
    });
  }
}
