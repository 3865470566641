import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'cup-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.css']
})
export class LegalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<LegalComponent>) { }

  ngOnInit() {
  }
  public close(){
    this.dialogRef.close();
  }
}
