import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/api/user.service';
import { ContinuingEducationService } from 'src/app/api/continuing-education.service';
import { FileViewDialogComponent } from '../file-view-dialog/file-view-dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DeleteService } from 'src/app/api/delete.service';
import { UpdateCeDialogComponent } from './update-ce-dialog/update-ce-dialog.component';
import { UtilService } from 'src/app/api/util.service';

@Component({
  selector: 'cup-continuing-education',
  templateUrl: './continuing-education.component.html',
  styleUrls: ['./continuing-education.component.css']
})
export class ContinuingEducationComponent implements OnInit {
  public data: any;
  private ceSubscrption: Subscription;
  private deleteSubscription: Subscription;
  private user;
  public continuingEdu: any[] = [];
  private saving = false;
  constructor(public fbuilder: FormBuilder, private userService: UserService,private utilService: UtilService,private continuingEducationService: ContinuingEducationService,private deleteService: DeleteService,
    public dialog: MatDialog, public snackbar: MatSnackBar) { 
    this.data = this.fbuilder.group({
      dateS: new FormControl(''),
      dateE: new FormControl(),
      description: new FormControl(''),
      credit: new FormControl(''),
      fileSource: new FormControl()
    });
  }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.continuingEducationService.getContinuingEducation(this.user);
    this.ceSubscrption = this.continuingEducationService.ceChanged.subscribe(ce => {
      if(ce.status === 'fail'){
        this.snackbar.open('Error inserting record','',{duration: 3000, panelClass: ['snackbar-error']});
      } else {
        this.continuingEdu = ce.data;
        if (this.saving) {
          this.clearForm();
          var sb = document.getElementById("save") as HTMLButtonElement;
          sb.disabled = false;
          this.snackbar.open('Continuing Education Entry added', '', { duration: 2000, panelClass: ['snackbar-success'] });
        }
      }
    });
    this.deleteSubscription = this.deleteService.valueChanged.subscribe(ce =>{
      if(ce != null){
        this.continuingEdu = ce.data;
        this.snackbar.open('Continuing Education Entry Deleted','',{duration: 2000,panelClass: ['snackbar-success']});
      }
    });
  }
  ngOnDestroy(){
    this.ceSubscrption.unsubscribe();
    this.deleteSubscription.unsubscribe();
  }
  public onFileChange(event) {
    const file = event.target.files[0];
    var check = this.utilService.checkFileExtention(file);
    if(check){
      this.data.patchValue({
        fileSource: file
      });
    }else{
      var myFile = document.getElementById('file') as HTMLInputElement;
      myFile.value = '';
      this.snackbar.open('File Type Not Accepted','',{duration: 2000,panelClass: ['snackbar-error']});
    }
  }
  public openDialog(file: any){
    var dialogRef = this.dialog.open(FileViewDialogComponent,{
      width: "1024px",
      data: file
    });
  }
  public addContinuingEdu(){
    if(this.data.status == "INVALID"){
      this.snackbar.open('Please ensure all fields have been completed.','',{duration: 2000,panelClass: ['snackbar-error']});
    }else{
      if(this.data.value.fileSource != null){
        var formData = new FormData();
        formData.append("dateS", this.utilService.formatDate(this.data.value.dateS));
        formData.append("dateE", this.utilService.formatDate(this.data.value.dateE));
        formData.append("desc", this.data.value.description);
        formData.append("hours", this.data.value.credit);
        formData.append("fileSource", this.data.value.fileSource);
        this.continuingEducationService.addContinuingEducation(this.user, formData);
        this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
        this.saving = true;
        var sb = document.getElementById("save") as HTMLButtonElement;
        sb.disabled = true;
      }else{
        this.snackbar.open('File Required','',{duration: 2000,panelClass: ['snackbar-error']});
      }
    }
  }
  public openEditDialog(record: any){
    var dialogRef = this.dialog.open(UpdateCeDialogComponent,{
      width: "500px",
      data: record
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined && result != null){
        this.continuingEducationService.updateContinuingEducation(this.user, result);
        this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
        this.saving = true;
      }
    });
  }
  public deleteContinuingEdu(id: string){
    let del = confirm("Are you sure?");
    if(del){
      this.deleteService.deleteEntry(this.user,'T_CONT_EDU',id);
    }
  }
  public resetForm(){
    this.clearForm();
  }
  private clearForm(){
    var file = document.getElementById('file') as HTMLInputElement;
    file.value = '';
    this.data.reset();
  }
}
