import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material';

@Component({
  selector: 'cup-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  public data: FormGroup;
  constructor(public fbuilder: FormBuilder,public dialogRef: MatDialogRef<ChangePasswordComponent>,public dialog: MatDialog) { }

  ngOnInit() {
    this.data = this.fbuilder.group({
      cpassword: new FormControl('',Validators.required),
      password: new FormControl('',[Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{12,}')]),
      confirmpassword: new FormControl('',Validators.required)
    },{validator: this.matchPassword});
  }
  public changePassword(){
    if(this.data.valid){
      this.dialogRef.close(this.data.value);
    }
  }
  public cancel(){
    this.dialogRef.close();
  }
  matchPassword(control: AbstractControl): ValidationErrors | null {
 
    const password = control.get("password").value;
    const confirm = control.get("confirmpassword").value;
 
 
    if (password != confirm) { 
      control.get('confirmpassword').setErrors({'noMatch': true});
      return { 'noMatch': true } 
    }
    control.get('confirmpassword').setErrors(null);
    return null
 
  }
}
