import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationsService } from 'src/app/api/notifications.service';
import { UserService } from 'src/app/api/user.service';

@Component({
  selector: 'cup-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  public isLoaded = false;
  private user: any;
  public account:any;
  public message: any = '';
  public licensesExpired: any;
  public licensesExpiring: any;
  public certificationsExpired: any;
  public certificationsExpiring: any;
  public notificationsSubscription: Subscription;
  public messageSubscription: Subscription;
  constructor(public notificationsService: NotificationsService, private userService: UserService) { }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.notificationsService.getNotifications(this.user);
    this.notificationsService.getMessages();
    this.notificationsSubscription = this.notificationsService.notificationsChanged.subscribe(notification =>{
      if(notification != null && notification != 'undefined'){
        this.account = notification.dataAccount;
        this.licensesExpired =  notification.dataLicenses.filter(exp => exp.status === "Expired");
        this.licensesExpiring =  notification.dataLicenses.filter(exp => exp.status === "Expiring");
        this.certificationsExpired =  notification.dataCertifications.filter(exp => exp.status === "Expired");
        this.certificationsExpiring =  notification.dataCertifications.filter(exp => exp.status === "Expiring");
        this.isLoaded = true;
      }
    });
    this.messageSubscription = this.notificationsService.messagesChanged.subscribe(data => {
      if(data.status == "success"){
        this.message = data.data;
      }
    });
  }
  ngOnDestroy(){
    this.notificationsSubscription.unsubscribe();
    this.messageSubscription.unsubscribe();
  }
}
