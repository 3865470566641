import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'cup-cali-legal',
  templateUrl: './cali-legal.component.html',
  styleUrls: ['./cali-legal.component.css']
})
export class CaliLegalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CaliLegalComponent>) { }

  ngOnInit() {
  }
  public close(){
    this.dialogRef.close();
  }

}
