import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreatelinkService {
  public links: any;
  public linksChanged = new Subject<any>();
  public newestLink: any;
  public newestLinkChanged = new Subject<any>();
  constructor(private http: HttpClient) { }

  public insertLinkPacket(userId:String, data: any){
    this.http.post(`/rest/savePacketPDF.php?user=${userId}`,data).subscribe(status => {
    });
  }
  public getLinks(userId: String){
    this.http.get(`/rest/getLink.php?user=${userId}`).subscribe(data => {
      this.links = data;
      this.linksChanged.next(this.links);
    });
  }
  public cancelLink(userId: String,link: String){
    this.http.get(`/rest/cancelLink.php?user=${userId}&link=${link}`).subscribe(data => {
      this.links = data;
      this.linksChanged.next(this.links);
    });
  }
  public getNewestLinkInformation(userId: String){
    this.http.get(`/rest/getNewestLink.php?user=${userId}`).subscribe(data => {
      this.newestLink = data;
      this.newestLinkChanged.next(this.newestLink);
    });
  }
}
