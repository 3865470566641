import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CertificationsService {
  public certs: any;
  public certsChanged = new Subject<any>();

  constructor(private http: HttpClient) { }
  public getCertifications(userId: String){
    this.http.get(`/rest/getCertifications.php?user=${userId}`).subscribe(data => {
      this.certs = data;
      this.certsChanged.next(this.certs);
    });
  }
  public addCertification(user: any, data: any){
    this.http.post(`/rest/insertCertifications.php?user=${user}`,data).subscribe(result => {
      this.certs = result;
      this.certsChanged.next(this.certs);
    });
  }
  public updateCertification(user: any, data: any){
    this.http.post(`/rest/updateCertifications.php?user=${user}`,data).subscribe(result => {
      this.certs = result;
      this.certsChanged.next(this.certs);
    });
  }
}
