import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { PicklistService } from 'src/app/api/picklist.service';

@Component({
  selector: 'cup-update-education-dialog',
  templateUrl: './update-education-dialog.component.html',
  styleUrls: ['./update-education-dialog.component.css']
})
export class UpdateEducationDialogComponent implements OnInit {
  public record: any;
  private picklistSubscription: Subscription;
  public picklist;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<UpdateEducationDialogComponent>,public fbuilder: FormBuilder,public picklistService: PicklistService) { }

  ngOnInit() {
    this.record = this.fbuilder.group({
      name: new FormControl(this.data.name),
      address: new FormControl(this.data.addr),
      city: new FormControl(this.data.city),
      state: new FormControl(this.data.state),
      zip: new FormControl(this.data.zip),
      from: new FormControl(this.data.yearB),
      to: new FormControl(this.data.yearE),
      degree: new FormControl(this.data.degree),
      type: new FormControl(this.data.type),
      description: new FormControl(this.data.desc)
    });
    this.picklistService.getStates();
    this.picklistSubscription = this.picklistService.statesChanged.subscribe(states => {
      this.picklist = states.data;
    });
  }
  ngOnDestroy(){
    this.picklistSubscription.unsubscribe();
  }
  public update(){
    if(this.data.status == "INVALID"){
      alert("Please ensure all fields have been completed.");
    }else{
      var formData = new FormData();
      formData.append("id", this.data.id);
      formData.append("type", this.record.value.type);
      formData.append("name", this.record.value.name);
      formData.append("addr", this.record.value.address);
      formData.append("city", this.record.value.city);
      formData.append("state", this.record.value.state);
      formData.append("zip", this.record.value.zip);
      formData.append("yearB", this.record.value.from);
      formData.append("yearE", this.record.value.to);
      formData.append("degree", this.record.value.degree);
      formData.append("desc", this.record.value.description);
      this.dialogRef.close(formData);
    }
  }
  public cancel(){
    this.dialogRef.close(null);
  }
}
