import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UserDashboardComponent } from './user-dashboard.component';
import { ProfileInformationComponent } from './profile-information/profile-information.component';
import { PhysicalExamComponent } from './physical-exam/physical-exam.component';
import { ImmunizationsComponent } from './immunizations/immunizations.component';
import { InsuranceComponent } from './insurance/insurance.component';
import { EducationComponent } from './education/education.component';
import { FellowshipComponent } from './fellowship/fellowship.component';
import { LicensesComponent } from './licenses/licenses.component';
import { CertificationsComponent } from './certifications/certifications.component';
import { WorkHistoryComponent } from './work-history/work-history.component';
import { ContinuingEducationComponent } from './continuing-education/continuing-education.component';
import { CaselogsComponent } from './caselogs/caselogs.component';
import { ReferencesComponent } from './references/references.component';
import { OtherUploadsComponent } from './other-uploads/other-uploads.component';
import { CreateLinkComponent } from './create-link/create-link.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ViewCancelComponent } from './view-cancel/view-cancel.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { CancelSubscriptionComponent } from './cancel-subscription/cancel-subscription.component';

const routes: Routes = [
  { path: '', redirectTo: 'notifications', pathMatch: 'full'},
  { path: '', component: UserDashboardComponent,
  children:[
    {path: "profile-information", component: ProfileInformationComponent},
    {path: "physical-exam", component: PhysicalExamComponent},
    {path: "immunizations", component: ImmunizationsComponent},
    {path: "insurance", component: InsuranceComponent},
    {path: "education", component: EducationComponent},
    {path: "fellowship", component: FellowshipComponent},
    {path: "licenses", component: LicensesComponent},
    {path: "certifications", component: CertificationsComponent},
    {path: "work-history", component: WorkHistoryComponent},
    {path: "continuing-education", component: ContinuingEducationComponent},
    {path: "caselogs", component: CaselogsComponent},
    {path: "references", component: ReferencesComponent},
    {path: "other-uploads", component: OtherUploadsComponent},
    {path: "create-packet", component: CreateLinkComponent},
    {path: "confirmation", component: ConfirmationComponent},
    {path: "notifications", component: NotificationsComponent},
    {path: "view-cancel", component: ViewCancelComponent},
    {path: "assist", component: ContactFormComponent},
    {path: "cancel-subscription", component: CancelSubscriptionComponent}
  ]
  }]
;

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserDashboardRoutingModule { }
