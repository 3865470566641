import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CreatelinkService } from 'src/app/api/createlink.service';
import { UserService } from 'src/app/api/user.service';

@Component({
  selector: 'cup-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {
  private linkSubscription: Subscription;
  public link: any;
  private user: any;
  constructor(private createLinkService: CreatelinkService,private userService: UserService) { }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.createLinkService.getNewestLinkInformation(this.user);
    this.linkSubscription = this.createLinkService.newestLinkChanged.subscribe(link => {
      this.link = link.data;
    });
  }
  ngOnDestroy(){
    this.linkSubscription.unsubscribe();
  }

}
