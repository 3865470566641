import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdvertiseService {

  constructor(private http: HttpClient) { }

  public ad: any;
  public adChanged = new Subject<any>();

  public getAdvertisements(page: any){
    this.http.get(`/rest/getAds.php?page=${page}`).subscribe(data => {
      this.ad = data;
      this.adChanged.next(this.ad);
    });
  }
}
