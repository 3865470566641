import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PacketService {
  public packet: any;
  public packetChanged = new Subject<any>();
  constructor(private http: HttpClient) { }

  public getPacket(lastName: String, code: String){
    this.http.get(`/rest/getPacketPDF.php?lname=${lastName}&password=${code}`).subscribe(data => {
      this.packet = data;
      this.packetChanged.next(this.packet);
    });
  }
}
