import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/api/user.service';

@Component({
  selector: 'cup-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {
  private user: any;
  public data: any;
  private saving = false;
  private userServiceSubcription: Subscription;
  constructor(public userService: UserService,public snackbar: MatSnackBar, public fbuilder: FormBuilder) { 
    this.data = this.fbuilder.group({
      problem: new FormControl('',Validators.minLength(20))
    });
  }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.userServiceSubcription = this.userService.assistChanged.subscribe(data =>{
      if(data == "success"){
        if (this.saving) {
          this.clearForm();
          var sb = document.getElementById("save") as HTMLButtonElement;
          sb.disabled = false;
          this.snackbar.open('Send Successful', '', { duration: 2000, panelClass: ['snackbar-success'] });
        }
        
      }else{
        this.snackbar.open('Failed to Send','Contact support@cre-denseupload.com',{duration: 3000, panelClass: ['snackbar-error']});
      }
    });
  }
  ngOnDestroy(){
    this.userServiceSubcription.unsubscribe();
  }
  public send(){
    if(this.data.status == "INVALID"){
      alert("Please ensure all fields have been completed.");
    }else{
      var formData = new FormData();
      formData.append("problem", this.data.value.problem);
      this.userService.sendAssistance(this.user,formData);
      this.snackbar.open('Sending Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
      this.saving = true;
      var sb = document.getElementById("save") as HTMLButtonElement;
      sb.disabled = true;
    }
  }
  public resetForm(){
    this.clearForm();
  }
  private clearForm(){
    this.data.reset();
  }
}
