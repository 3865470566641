import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'cup-cali-opt-out',
  templateUrl: './cali-opt-out.component.html',
  styleUrls: ['./cali-opt-out.component.css']
})
export class CaliOptOutComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CaliOptOutComponent>) { }

  ngOnInit() {
  }
  public close(){
    this.dialogRef.close();
  }
}
