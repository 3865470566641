import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';

@Component({
  selector: 'cup-legal-link-dialog',
  templateUrl: './legal-link-dialog.component.html',
  styleUrls: ['./legal-link-dialog.component.css']
})
export class LegalLinkDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<LegalLinkDialogComponent>,public dialog: MatDialog) { }

  ngOnInit() {
  }
  confirm(){
    this.dialogRef.close(true);
  }
  cancel(){
    this.dialogRef.close(false);
  }
}
