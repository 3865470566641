import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const httpHeaders = {
  headers: new HttpHeaders({
    "Content-Type": "application/json"
  })
}

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient) { }

  public register(data: any){
    var dataString = JSON.stringify(data);
    this.http.post(`/rest/insertRegistration.php`,dataString,httpHeaders).subscribe(result => {

    });
  }
}
