import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/api/user.service';
import { PhysicalService } from 'src/app/api/physical.service';
import { FileViewDialogComponent } from '../file-view-dialog/file-view-dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DeleteService } from 'src/app/api/delete.service';
import { UpdatePhysicalDialogComponent } from './update-physical-dialog/update-physical-dialog.component';
import { UtilService } from 'src/app/api/util.service';
import { Router } from '@angular/router';
@Component({
  selector: 'cup-physical-exam',
  templateUrl: './physical-exam.component.html',
  styleUrls: ['./physical-exam.component.css']
})
export class PhysicalExamComponent implements OnInit {
  public data: FormGroup;
  private physicalSubscription: Subscription;
  private deleteSubscription: Subscription;
  public physicals: any = [];
  public labs: any = [];
  public fits: any = [];
  private user: any;
  private physicalAdded = false;
  constructor(private router: Router, public fbuilder: FormBuilder, private userService: UserService,private utilService: UtilService, private physicalService: PhysicalService,private deleteService: DeleteService,public dialog: MatDialog,public snackbar: MatSnackBar) { 
    this.data = this.fbuilder.group({
      date: new FormControl('', Validators.required),
      physical: new FormControl(''),
      type: new FormControl('', Validators.required)
    });
  }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.physicalService.getPhysicals(this.user);
    this.physicalSubscription = this.physicalService.physicalsChanged.subscribe(physicals =>{
      if(physicals != null){
        if(physicals.status === 'fail'){
          this.snackbar.open('Error inserting record','',{duration: 3000, panelClass: ['snackbar-error']});
        }else{
          this.physicals = physicals.data.filter(type => type.type === "P");
          this.labs = physicals.data.filter(type => type.type === "L");
          this.fits = physicals.data.filter(type => type.type === "M");
          if(this.physicalAdded){
            this.snackbar.open('Physical Entry added','',{duration: 2000,panelClass: ['snackbar-success']});
            var sb = document.getElementById("save") as HTMLButtonElement;
            sb.disabled = false;
            this.clearForm();
          }
        }
      }
    });
    this.deleteSubscription = this.deleteService.valueChanged.subscribe(physicals =>{
      if(physicals != null){
        this.physicals = physicals.data.filter(type => type.type === "P");
        this.labs = physicals.data.filter(type => type.type === "L");
        this.fits = physicals.data.filter(type => type.type === "M");
        this.snackbar.open('Physical Entry Deleted','',{duration: 2000,panelClass: ['snackbar-success']});
      }
    });
  }
  ngOnDestroy(){
    this.physicalSubscription.unsubscribe();
    this.deleteSubscription.unsubscribe();
  }
  public openDialog(file: any){
    var dialogRef = this.dialog.open(FileViewDialogComponent,{
      width: "1150px",
      data: file
    });
  }
  public enterPhysical(){
    if(this.data.status == "INVALID"){
      alert("Please ensure all fields have been completed.");
    }else{
      if(this.data.value.physical != null){
        var formData = new FormData();
        formData.append("eDate", this.utilService.formatDate(this.data.value.date));
        formData.append("type", this.data.value.type);
        formData.append("fileSource", this.data.value.physical);
        this.physicalService.insertPhysical(this.user, formData);
        this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
        this.physicalAdded = true;
        var sb = document.getElementById("save") as HTMLButtonElement;
        sb.disabled = true;
      }else{
        this.snackbar.open('File Required','',{duration: 2000,panelClass: ['snackbar-error']});
      }
    }
  }
  public openEditDialog(record: any){
    var dialogRef = this.dialog.open(UpdatePhysicalDialogComponent,{
      width: "500px",
      data: record
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined && result != null){
        this.physicalService.updatePhysical(this.user, result);
        this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
        this.physicalAdded = true;
      }
    });
  }
  public deletePhysical(id: string){
    let del = confirm("Are you sure?");
    if(del){
      this.deleteService.deleteEntry(this.user,'PHYS_EXAM',id);
    }
  }
  public onFileChange(event) {
    const file = event.target.files[0];
    var check = this.utilService.checkFileExtention(file);
    if(check){
      this.data.patchValue({
        physical: file 
      });
    }else{
      var myFile = document.getElementById('file') as HTMLInputElement;
      myFile.value = '';
      this.snackbar.open('File Type Not Accepted','',{duration: 2000,panelClass: ['snackbar-error']});
    }
  }
  public resetPage(){
    this.clearForm();
  }
  private clearForm(){
    var file = document.getElementById('file') as HTMLInputElement;
    file.value = '';
    this.data.reset();
  }
}
