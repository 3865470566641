import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'cup-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PrivacyComponent>) { }

  ngOnInit() {
  }
  public close(){
    this.dialogRef.close();
  }
}
