import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/api/user.service';

@Component({
  selector: 'cup-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {
  public title: any = "";
  constructor(private userService: UserService) { }

  ngOnInit() {
    this.title = this.userService.getTitle();
  }

}
