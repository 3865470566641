import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject } from 'rxjs';

const httpHeaders = {
  headers: new HttpHeaders({
    "Content-Type": "application/json"
  })
}

@Injectable({
  providedIn: 'root'
})
export class LicenseService {
  public license: any;
  public licenseChanged = new Subject<any>();

  constructor(private http: HttpClient) { }
  public getLicense(userId: String){
    this.http.get(`/rest/getLicenses.php?user=${userId}`).subscribe(data => {
      this.license = data;
      this.licenseChanged.next(this.license);
    });
  }
  public addLicense(user: any, data: any){
    this.http.post(`/rest/insertLicenses.php?user=${user}`,data).subscribe(result => {
      this.license = result;
      this.licenseChanged.next(this.license);
    });
  }
  public updateLicense(user: any, data: any){
    this.http.post(`/rest/updateLicenses.php?user=${user}`,data).subscribe(result => {
      this.license = result;
      this.licenseChanged.next(this.license);
    });
  }
}
