import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EducationService {
  public education: any;
  public educationChanged = new Subject<any>();
  constructor(private http: HttpClient) { }

  public insertEducation(userId:String, data: any){
    this.http.post(`/rest/insertEducations.php?user=${userId}`,data).subscribe(status => {
      this.education = status;
      this.educationChanged.next(this.education);
    });
  }
  public updateEducation(userId:String, data: any){
    this.http.post(`/rest/updateEducations.php?user=${userId}`,data).subscribe(status => {
      this.education = status;
      this.educationChanged.next(this.education);
    });
  }
  public getEducation(userId: String){
    this.http.get(`/rest/getEducations.php?user=${userId}`).subscribe(data => {
      this.education = data;
      this.educationChanged.next(this.education);
    });
  }
}
