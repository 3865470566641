import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AdvertiseService } from '../api/advertise.service';
import { NewsService } from '../api/news.service';

@Component({
  selector: 'cup-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  public newsSubscription = new Subscription();
  public news = [];
  public adsSubscription = new Subscription();
  public ads = [];
  
  constructor(public newsService: NewsService,private advertiseService: AdvertiseService) { }
  ngOnInit() {
    this.newsService.getNews();
    this.newsSubscription = this.newsService.newsChanged.subscribe(news => {
      this.news = news.data;
    });
    this.advertiseService.getAdvertisements('N');
    this.adsSubscription = this.advertiseService.adChanged.subscribe(ads => {
     this.ads = ads.data;
    });
  }
  ngOnDestroy() {
    this.newsSubscription.unsubscribe();
    this.adsSubscription.unsubscribe();
  }
}