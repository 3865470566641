import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UtilService } from 'src/app/api/util.service';

@Component({
  selector: 'cup-update-immunizations-dialog',
  templateUrl: './update-immunizations-dialog.component.html',
  styleUrls: ['./update-immunizations-dialog.component.css']
})
export class UpdateImmunizationsDialogComponent implements OnInit {
  public record: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<UpdateImmunizationsDialogComponent>,public fbuilder: FormBuilder,private utilService: UtilService) { }

  ngOnInit() {
    this.record = this.fbuilder.group({
      date: new FormControl(new Date(this.data.dte), Validators.required),
      type: new FormControl(this.data.type,Validators.required),
      description: new FormControl(this.data.other)
    });
  }
  public update(){
    if(this.data.status == "INVALID"){
      alert("Please ensure all fields have been completed.");
    }else{
      var formData = new FormData();
      formData.append("id", this.data.id);
      formData.append("dte", this.utilService.formatDate(this.record.value.date));
      formData.append("type", this.record.value.type);
      formData.append("other", this.record.value.description);
      this.dialogRef.close(formData);
    }
  }
  public cancel(){
    this.dialogRef.close(null);
  }
}
