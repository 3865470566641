import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/api/profile.service';
import { UserService } from 'src/app/api/user.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ReferencesService } from 'src/app/api/references.service';
import { Subscription } from 'rxjs';
import { PicklistService } from 'src/app/api/picklist.service';
import { FileViewDialogComponent } from '../file-view-dialog/file-view-dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DeleteService } from 'src/app/api/delete.service';
import { UpdateReferenceDialogComponent } from './update-reference-dialog/update-reference-dialog.component';
import { UtilService } from 'src/app/api/util.service';
import { AddDocumentDialogComponent } from '../add-document-dialog/add-document-dialog.component';
import { AddDocumentService } from 'src/app/api/add-document.service';

@Component({
  selector: 'cup-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.css']
})
export class ReferencesComponent implements OnInit {
  private user;
  public data;
  private referenceSubscription: Subscription;
  private picklistSubscription: Subscription;
  private deleteSubscription: Subscription;
  private documentSubscription: Subscription;
  public picklist;
  public references: any[] = [];
  private saving = false;

  constructor(public referencesService: ReferencesService, private deleteService: DeleteService, public userService: UserService, public fbuilder: FormBuilder,
     public picklistService: PicklistService,public dialog: MatDialog, public snackbar: MatSnackBar,private utilService: UtilService,private addDocumentService: AddDocumentService) {
    this.data = this.fbuilder.group({
      name: new FormControl(''),
      address: new FormControl(''),
      address2: new FormControl,
      city: new FormControl(''),
      state: new FormControl(''),
      zip: new FormControl(''),
      phone: new FormControl(''),
      email: new FormControl(''),
      reference: new FormControl()
    });
  }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.referencesService.getReferences(this.user);
    this.picklistService.getStates();
    this.referenceSubscription = this.referencesService.referencesChanged.subscribe(references =>{
      if(references.status === 'fail'){
        this.snackbar.open('Error inserting record','',{duration: 3000, panelClass: ['snackbar-error']});
      }else{
        this.references = references.data;
        if(this.saving){
          this.clearForm();
          var sb = document.getElementById("save") as HTMLButtonElement;
          sb.disabled = false;
          this.snackbar.open('Reference Entry added','',{duration: 2000,panelClass: ['snackbar-success']});
        }
      }
    });
    this.picklistSubscription = this.picklistService.statesChanged.subscribe(states => {
      this.picklist = states.data;
    });
    this.deleteSubscription = this.deleteService.valueChanged.subscribe(references =>{
      if(references != null){
        this.references = references.data;
        this.snackbar.open('Reference Entry Deleted','',{duration: 2000,panelClass: ['snackbar-success']});
      }
    });
    this.documentSubscription = this.addDocumentService.statusChanged.subscribe(status => {
      if(status.status == "success"){
        this.snackbar.open('Document added to Record','',{duration: 2000,panelClass: ['snackbar-success']});
        this.referencesService.getReferences(this.user);
      }else{
        this.snackbar.open('Error adding Document to Record','',{duration: 2000,panelClass: ['snackbar-error']});
      }
    });
  }
  ngOnDestroy(){
    this.referenceSubscription.unsubscribe();
    this.picklistSubscription.unsubscribe();
    this.deleteSubscription.unsubscribe();
    this.documentSubscription.unsubscribe();
  }
  public openDialog(file: any){
    var dialogRef = this.dialog.open(FileViewDialogComponent,{
      width: "1024px",
      data: file
    });
  }
  public addReference(){
    if(this.data.status == "INVALID"){
      this.snackbar.open('Please ensure all fields have been completed.','',{duration: 2000,panelClass: ['snackbar-error']});
    }else{
      var formData = new FormData();
      formData.append("name", this.data.value.name);
      formData.append("addr1", this.data.value.address);
      formData.append("addr2", this.data.value.address2);
      formData.append("city", this.data.value.city);
      formData.append("state", this.data.value.state);
      formData.append("zip", this.data.value.zip);
      formData.append("phone", this.data.value.phone);
      formData.append("email", this.data.value.email);
      formData.append("fileSource", this.data.value.reference);
      this.referencesService.addReference(this.user,formData);
      this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
      this.saving = true;
      var sb = document.getElementById("save") as HTMLButtonElement;
      sb.disabled = true;
    }
  }
  public openEditDialog(record: any){
    var dialogRef = this.dialog.open(UpdateReferenceDialogComponent,{
      width: "750px",
      data: record
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined && result != null){
        this.referencesService.updateReference(this.user, result);
        this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
        this.saving = true;
      }
    });
  }
  public openAddDocDialog(record: any){
    var data = {"id": record.id,"type": "T_REF"};
    var dialogRef = this.dialog.open(AddDocumentDialogComponent,{
      width: "500px",
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined && result != null){
        this.addDocumentService.addDocument(this.user,result);
        this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
      }
    });
  }
  public deleteReference(id: string){
    let del = confirm("Are you sure?");
    if(del){
      this.deleteService.deleteEntry(this.user,'T_REF',id);
    }
  }
  public onFileChange(event) {
    const file = event.target.files[0];
    var check = this.utilService.checkFileExtention(file);
    if(check){
      this.data.patchValue({
        reference: file
      });
    }else{
      var myFile = document.getElementById('file') as HTMLInputElement;
      myFile.value = '';
      this.snackbar.open('File Type Not Accepted','',{duration: 2000,panelClass: ['snackbar-error']});
    }
  }
  public resetForm(){
    this.clearForm();
  }
  private clearForm(){
    var file = document.getElementById('file') as HTMLInputElement;
    file.value = '';
    this.data.reset();
  }
  
}
