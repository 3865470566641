import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


const httpHeaders = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    "Authorization": 'Basic ' + btoa('AeipsuwjNW8BZa8KrP8LWicyYCEamc5wQfpDd29OrVXUkCmfb3t0G2IoScQzR_JZQ7X8VH1RUQP-HZgX:EDbpZVP38oQQGRtDn_QJhQ4x1yAoqhhFAZJDpNsx4_gR-tswD1JnXKKjjlC9XUsU9KyQj3ZFCDfRVv7p'),
    "PayPal-Request-Id":"PLAN-180110621-002",
    "Prefer":"return=representation",
    "Accept":"application/json"
  })
}

@Injectable({
  providedIn: 'root'
})
export class PaypalService {
  
  private PAYPAL_SANDBOX_API = 'https://api-m.sandbox.paypal.com/v1/catalogs/products';
  public paypal: any;
  public paypalChanged = new Subject<any>();
  public sub: any;
  public subChanged = new Subject<any>();

  constructor(private http: HttpClient) { }

  public completeSubscription(subID: String, userId:String, plan: any){
    var data = {
      "plan": plan,
      "subID": subID
    };
    this.http.post(`/rest/completeSubscription.php?user=${userId}`,data).subscribe(data => {
      this.sub = data;
      this.subChanged.next(this.sub);
    });
  }
  public cancelSubscription(userId:String, data: any){
    this.http.post(`/rest/cancelSubscription.php?user=${userId}`,data).subscribe(data => {
      this.paypal = data;
      this.paypalChanged.next(this.paypal);
    });
  }
  public checkForProducts(){
    this.http.get(this.PAYPAL_SANDBOX_API,httpHeaders).subscribe(data => {
      this.paypal = data;
      this.paypalChanged.next(this.paypal);
    });
  }
  public createAProduct(){
    var data = {
      "name": "Six Month Service",
      "description": "Six month subscription for CRE-Dense Upload",
      "type": "SERVICE",
      "category": "SOFTWARE"
    };
    var data2 = {
      "name": "One Year Service",
      "description": "One Year subscription for CRE-Dense Upload",
      "type": "SERVICE",
      "category": "SOFTWARE"
    };
    this.http.post(this.PAYPAL_SANDBOX_API,data2,httpHeaders).subscribe(data => {
      this.paypal = data;
      this.paypalChanged.next(this.paypal);
    });
  }
  public createAPlan(){
    var data = {
      "product_id": "PROD-6TL61834VU022114C",
      "name": "Six Month Plan",
      "description": "Six Month Plan for CRE-Dense Upload",
      "billing_cycles": [
        {
          "frequency": {
            "interval_unit": "MONTH",
            "interval_count": 6
          },
          "tenure_type": "REGULAR",
          "sequence": 1,
          "total_cycles": 2,
          "pricing_scheme": {
            "fixed_price": {
              "value": "216",
              "currency_code": "USD"
            }
          }
        }
      ],
      "payment_preferences": {
        "auto_bill_outstanding": true,
        "setup_fee": {
          "value": "0",
          "currency_code": "USD"
        },
        "setup_fee_failure_action": "CONTINUE",
        "payment_failure_threshold": 3
      }
    };
    var data2 = {
      "product_id": "PROD-88T142375N0942511",
      "name": "One Year Plan",
      "description": "One Year Plan for CRE-Dense Upload",
      "billing_cycles": [
        {
          "frequency": {
            "interval_unit": "YEAR",
            "interval_count": 1
          },
          "tenure_type": "REGULAR",
          "sequence": 1,
          "total_cycles": 1,
          "pricing_scheme": {
            "fixed_price": {
              "value": "336",
              "currency_code": "USD"
            }
          }
        }
      ],
      "payment_preferences": {
        "auto_bill_outstanding": true,
        "setup_fee": {
          "value": "0",
          "currency_code": "USD"
        },
        "setup_fee_failure_action": "CONTINUE",
        "payment_failure_threshold": 3
      }
    };
    this.http.post('https://api-m.sandbox.paypal.com/v1/billing/plans',data2,httpHeaders).subscribe(data => {
      this.paypal = data;
      this.paypalChanged.next(this.paypal);
    });
  }
}
