import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDatepicker } from '@angular/material';
import { UtilService } from 'src/app/api/util.service';

@Component({
  selector: 'cup-update-caselog-dialog',
  templateUrl: './update-caselog-dialog.component.html',
  styleUrls: ['./update-caselog-dialog.component.css']
})
export class UpdateCaselogDialogComponent implements OnInit {
  public record: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private utilService: UtilService, public dialogRef: MatDialogRef<UpdateCaselogDialogComponent>,public fbuilder: FormBuilder) { 
    
  }

  ngOnInit() {
    this.record = this.fbuilder.group({
      date: new FormControl(new Date(this.data.dte),Validators.required),
      exDate: new FormControl(new Date(this.data.exDte)),
      description: new FormControl(this.data.desc,Validators.required)
    });
  }
  public update(){
    if(this.data.status == "INVALID"){
      alert("Please ensure all fields have been completed.");
    }else{
      var formData = new FormData();
      formData.append("id", this.data.id);
      formData.append("dte", this.utilService.formatDate(this.record.value.date));
      formData.append("exDte", this.utilService.formatDate(this.record.value.exDate));
      formData.append("desc", this.record.value.description);
      this.dialogRef.close(formData);
    }
  }
  public cancel(){
    this.dialogRef.close(null);
  }
}
