import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'cup-update-other-dialog',
  templateUrl: './update-other-dialog.component.html',
  styleUrls: ['./update-other-dialog.component.css']
})
export class UpdateOtherDialogComponent implements OnInit {
  public record: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<UpdateOtherDialogComponent>,public fbuilder: FormBuilder) { }

  ngOnInit() {
    this.record = this.fbuilder.group({
      description: new FormControl(this.data.desc,Validators.required)
    });
  }
  public update(){
    if(this.data.status == "INVALID"){
      alert("Please ensure all fields have been completed.");
    }else{
      var formData = new FormData();
      formData.append("id", this.data.id);
      formData.append("desc", this.record.value.description);
      this.dialogRef.close(formData);
    }
  }
  public cancel(){
    this.dialogRef.close(null);
  }
}
