import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImmunizationsService {
  public immunizations: any;
  public immunizationsChanged = new Subject<any>();
  constructor(private http: HttpClient) { }

  public insertImmunization(userId:String, data: any){
    this.http.post(`/rest/insertImmunizations.php?user=${userId}`,data).subscribe(status => {
      this.immunizations = status;
      this.immunizationsChanged.next(this.immunizations);
    });
  }
  public updateImmunization(userId:String, data: any){
    this.http.post(`/rest/updateImmunizations.php?user=${userId}`,data).subscribe(status => {
      this.immunizations = status;
      this.immunizationsChanged.next(this.immunizations);
    });
  }
  public getImmunizations(userId: String){
    this.http.get(`/rest/getImmunizations.php?user=${userId}`).subscribe(data => {
      this.immunizations = data;
      this.immunizationsChanged.next(this.immunizations);
    });
  }
}
