import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/api/user.service';
import { CertificationsService } from 'src/app/api/certifications.service';
import { FileViewDialogComponent } from '../file-view-dialog/file-view-dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DeleteService } from 'src/app/api/delete.service';
import { UpdateCertDialogComponent } from './update-cert-dialog/update-cert-dialog.component';
import { UtilService } from 'src/app/api/util.service';
import { AddDocumentService } from 'src/app/api/add-document.service';
import { AddDocumentDialogComponent } from '../add-document-dialog/add-document-dialog.component';

@Component({
  selector: 'cup-certifications',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.css']
})
export class CertificationsComponent implements OnInit {
  public picker: any;
  public expicker: any;
  public data: any;
  private certificationsSubscrption: Subscription;
  private deleteSubscription: Subscription;
  private documentSubscription: Subscription;
  private user;
  public alcsList: any[] = [];
  public palsList: any[] = [];
  public blsList: any[] = [];
  public nrpList: any[] = [];
  public traumaList: any[] = [];
  public otherList: any[] = [];
  public membershipList: any[] = [];
  private saving = false;
  public specialty = "Primary Specialty";
  public agency = "Issuing Agency";
  public date = "Issue Date";
  public exDate = "Expiration Date";

  constructor(public fbuilder: FormBuilder, private userService: UserService,private utilService: UtilService, private certificationService: CertificationsService,private deleteService: DeleteService,
    public dialog: MatDialog,public snackbar: MatSnackBar,private addDocumentService: AddDocumentService) { 
    this.data = this.fbuilder.group({
      date: new FormControl(''),
      exDate: new FormControl(''),
      type: new FormControl(''),
      specialty: new FormControl(''),
      agency: new FormControl(''),
      fileSource: new FormControl()
    });
  }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.certificationService.getCertifications(this.user);
    this.certificationsSubscrption = this.certificationService.certsChanged.subscribe(certs => {
      if(certs.status === 'fail'){
        this.snackbar.open('Error inserting record','',{duration: 3000, panelClass: ['snackbar-error']});
      } else {
        this.alcsList = certs.data.filter(alcs => alcs.type === "A");
        this.palsList = certs.data.filter(pals => pals.type === "P");
        this.blsList = certs.data.filter(bls => bls.type === "B");
        this.nrpList = certs.data.filter(nrp => nrp.type === "N");
        this.traumaList = certs.data.filter(trauma => trauma.type === "T");
        this.otherList = certs.data.filter(other => other.type === "O");
        this.membershipList = certs.data.filter(member => member.type === "M");
        if (this.saving) {
          var sb = document.getElementById("save") as HTMLButtonElement;
          sb.disabled = false;
          this.snackbar.open('Entry added', '', { duration: 2000, panelClass: ['snackbar-success'] });
          this.clearForm();
        }
      }
    });
    this.deleteSubscription = this.deleteService.valueChanged.subscribe(certs =>{
      if(certs != null){
        this.alcsList = certs.data.filter(alcs => alcs.type === "A");
        this.palsList = certs.data.filter(pals => pals.type === "P");
        this.blsList = certs.data.filter(bls => bls.type === "B");
        this.nrpList = certs.data.filter(nrp => nrp.type === "N");
        this.traumaList = certs.data.filter(trauma => trauma.type === "T");
        this.otherList = certs.data.filter(other => other.type === "O");
        this.snackbar.open('Entry Deleted','',{duration: 2000,panelClass: ['snackbar-success']});
      }
    });
    this.documentSubscription = this.addDocumentService.statusChanged.subscribe(status => {
      if(status.status == "success"){
        this.snackbar.open('Document added to Record','',{duration: 2000,panelClass: ['snackbar-success']});
        this.certificationService.getCertifications(this.user);
      }else{
        this.snackbar.open('Error adding Document to Record','',{duration: 2000,panelClass: ['snackbar-error']});
      }
    });
  }
  ngOnDestroy(){
    this.certificationsSubscrption.unsubscribe();
    this.deleteSubscription.unsubscribe();
    this.documentSubscription.unsubscribe();
  }
  public openDialog(file: any){
    var dialogRef = this.dialog.open(FileViewDialogComponent,{
      width: "1024px",
      data: file
    });
  }
  public onFileChange(event) {
    const file = event.target.files[0];
    var check = this.utilService.checkFileExtention(file);
    if(check){
      this.data.patchValue({
        fileSource: file
      });
    }else{
      var myFile = document.getElementById('file') as HTMLInputElement;
      myFile.value = '';
      this.snackbar.open('File Type Not Accepted','',{duration: 2000,panelClass: ['snackbar-error']});
    }
  }
  public addCertification(){
    if(this.data.status == "INVALID"){
      alert("Please ensure all fields have been completed.");
    }else{
      var formData = new FormData();
      formData.append("i_Date", this.utilService.formatDate(this.data.value.date));
      formData.append("e_Date", this.utilService.formatDate(this.data.value.exDate));
      formData.append("type", this.data.value.type);
      formData.append("spcl", this.data.value.specialty);
      formData.append("agency", this.data.value.agency);
      formData.append("fileSource", this.data.value.fileSource);
      this.certificationService.addCertification(this.user, formData);
      this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
      this.saving = true;
      var sb = document.getElementById("save") as HTMLButtonElement;
      sb.disabled = true;
    }
  }
  public openEditDialog(record: any){
    var dialogRef = this.dialog.open(UpdateCertDialogComponent,{
      width: "500px",
      data: record
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined && result != null){
        this.certificationService.updateCertification(this.user, result);
        this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
        this.saving = true;
      }
    });
  }
  public openAddDocDialog(record: any){
    var data = {"id": record.id,"type": "T_CERT"};
    var dialogRef = this.dialog.open(AddDocumentDialogComponent,{
      width: "500px",
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined && result != null){
        this.addDocumentService.addDocument(this.user,result);
        this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
      }
    });
  }
  public deleteCertification(id: string){
    let del = confirm("Are you sure?");
    if(del){
      this.deleteService.deleteEntry(this.user,'T_CERT',id);
    }
  }
  public checkLabels(){
    if(this.data.value.type == "M"){
      this.specialty = "Membership";
      this.agency = "Description";
      this.date = "From";
      this.exDate = "To";
    }else{
      this.specialty = "Primary Specialty";
      this.agency = "Issuing Agency";
      this.date = "Issue Date";
      this.exDate = "Expiration Date";
    }
  }
  public resetForm(){
    this.clearForm();
  }
  private clearForm(){
    var file = document.getElementById('file') as HTMLInputElement;
    file.value = '';
    this.data.reset();
  }
}
