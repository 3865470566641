import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UserService } from '../api/user.service';
import { RegisterService } from '../api/register.service';
import { Router } from '@angular/router';
import { MatSnackBar, MatDialog } from '@angular/material';
import { RegisterComponent } from '../landing-page/register/register.component';
import { FindUsernameComponent } from './find-username/find-username.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LocationStrategy } from '@angular/common';
import { AdvertiseService } from '../api/advertise.service';

@Component({
  selector: 'cup-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {
  public userSubscription = new Subscription;
  public data: FormGroup;
  public adsSubscription = new Subscription();
  public ads = [];

  constructor(private userService: UserService,private registerService: RegisterService,public fbuilder: FormBuilder,
    public router: Router,public snackbar: MatSnackBar,public dialog: MatDialog,private location: LocationStrategy,private advertiseService: AdvertiseService) { 
      history.pushState(null, null, window.location.href);  
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
    }

  ngOnInit() {
    this.data = this.fbuilder.group({
      username: "",
      password: ""
    });
    this.userSubscription = this.userService.userChanged.subscribe(user => {
      if(user.status != "error"){
        this.snackbar.open('Login Successful','',{duration: 3000, panelClass: ['snackbar-success']});
        if(user.status.toLowerCase() === 'expired'){
          this.router.navigateByUrl('package-purchase');
        }else{
          if(user.status.toLowerCase() === 'notactivated'){
            this.snackbar.open('Account is not Active','Please activate your account',{duration: 3000, panelClass: ['snackbar-error']});
          }else{
            this.router.navigateByUrl('user');
          }
        }
      }else{
        this.snackbar.open('Username or Password incorrect','',{duration: 3000, panelClass: ['snackbar-error']});
      }
    });
    this.advertiseService.getAdvertisements('S');
    this.adsSubscription = this.advertiseService.adChanged.subscribe(ads => {
     this.ads = ads.data;
    });
  }
  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    this.adsSubscription.unsubscribe();
  }
  public signIn(){
    var data =this.data.value;
    this.userService.get(data);
  }
  public register(){
    const dialogRef = this.dialog.open(RegisterComponent, {
      width: '700px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != null){
        this.registerService.register(result);
        this.snackbar.open('Registration Successful','Please check your email for activation.',{duration: 3000, panelClass: ['snackbar-success']});
      }
    });
  }
  public forgotUsername(){
    const dialogRef = this.dialog.open(FindUsernameComponent, {
      width: '425px',
    });
  }
  public forgotPassword(){
    const dialogRef = this.dialog.open(ResetPasswordComponent, {
      width: '425px',
    });
  }
  preSignIn(event: KeyboardEvent) {
    if(event.keyCode === 13){
      this.signIn();
    }
  }
}