import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UtilService } from 'src/app/api/util.service';

@Component({
  selector: 'cup-update-ce-dialog',
  templateUrl: './update-ce-dialog.component.html',
  styleUrls: ['./update-ce-dialog.component.css']
})
export class UpdateCeDialogComponent implements OnInit {
  public record: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private utilService: UtilService, public dialogRef: MatDialogRef<UpdateCeDialogComponent>,public fbuilder: FormBuilder) { }

  ngOnInit() {
    this.record = this.fbuilder.group({
      dateS: new FormControl(new Date(this.data.dateS)),
      dateE: new FormControl(new Date(this.data.dateE)),
      description: new FormControl(this.data.desc),
      credit: new FormControl(this.data.hours)
    });
  }
  public update(){
    if(this.data.status == "INVALID"){
      alert("Please ensure all fields have been completed.");
    }else{
      var formData = new FormData();
      formData.append("id", this.data.id);
      formData.append("dateS", this.utilService.formatDate(this.record.value.dateS));
      formData.append("dateE", this.utilService.formatDate(this.record.value.dateE));
      formData.append("desc", this.record.value.description);
      formData.append("hours", this.record.value.credit);
      this.dialogRef.close(formData);
    }
  }
  public cancel(){
    this.dialogRef.close(null);
  }
}
