import { LocationStrategy } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DemoDialogComponent } from './demo-dialog/demo-dialog.component';
import { MatExpansionPanelHeader } from '@angular/material';


@Component({
  selector: 'cup-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {
  @ViewChild('homePanel', {static: false}) homePanel: MatExpansionPanelHeader;
  @ViewChild('clinicianPanel', {static: false}) clinicianPanel: MatExpansionPanelHeader;
  @ViewChild('aboutPanel', {static: false}) aboutPanel: MatExpansionPanelHeader;
  @ViewChild('contactPanel', {static: false}) contactPanel: MatExpansionPanelHeader;

  homeExpanded = true;
  clinicianExpanded = false;
  aboutExpanded = false;
  contactExpanded = false;

  constructor(public dialog: MatDialog,private location: LocationStrategy) { 
    history.pushState(null, null, window.location.href);  
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
    
  }

  ngOnInit() {
    this.openDemo();
    
  }
  openDemo(){
    const dialogRef = this.dialog.open(DemoDialogComponent, {});

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  
}
