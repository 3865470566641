import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { PicklistService } from 'src/app/api/picklist.service';
import { UtilService } from 'src/app/api/util.service';

@Component({
  selector: 'cup-add-dialog',
  templateUrl: './add-dialog.component.html',
  styleUrls: ['./add-dialog.component.css']
})
export class AddDialogComponent implements OnInit {
  public data: FormGroup;
  public states;
  private picklistSubscription: Subscription;
  constructor(public fbuilder: FormBuilder,private utilService: UtilService,public dialogRef: MatDialogRef<AddDialogComponent>,public picklistService: PicklistService) { }

  ngOnInit() {
    this.data = this.fbuilder.group({
      licenseType:  new FormControl('', Validators.required),
      licenseDesc:  new FormControl('', Validators.required),
      licenseNumber:  new FormControl('', Validators.required),
      state:  new FormControl('', Validators.required),
      picker:  new FormControl('', Validators.required),
      expiration:  new FormControl('', Validators.required),
      practicing:  new FormControl('', Validators.required),
      fileSource: ''
    });
    //add call for state picklist
    this.picklistService.getStates();
    this.picklistSubscription = this.picklistService.statesChanged.subscribe(states => {
      this.states = states.data;
    });
  }
  ngOnDestroy(){
    this.data.reset();
    var file = document.getElementById('file') as HTMLInputElement;
    file.value = '';
    this.picklistSubscription.unsubscribe();
  }
  public onFileChange(event) {
    const file = event.target.files[0];
    var check = this.utilService.checkFileExtention(file);
    if(check){
      this.data.patchValue({
        fileSource: file
      });
    }else{
      var myFile = document.getElementById('file') as HTMLInputElement;
      myFile.value = '';
      alert('File Type Not Accpeted');
    }
  }
  public add(){
    if(this.data.status == "INVALID"){
      alert("Please ensure all fields have been completed.");
    }else{
      var formData = new FormData();
      formData.append("i_Date", this.utilService.formatDate(this.data.value.picker));
      formData.append("e_Date", this.utilService.formatDate(this.data.value.expiration));
      formData.append("type", this.data.value.licenseType);
      formData.append("desc",this.data.value.licenseDesc);
      formData.append("num", this.data.value.licenseNumber);
      formData.append("state", this.data.value.state);
      formData.append("prac", this.data.value.practicing);
      formData.append("fileSource", this.data.value.fileSource);
      this.dialogRef.close(formData);
    }
  }
  public cancel(){
    this.dialogRef.close(null);
  }
}
