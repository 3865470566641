import { Component, OnInit } from '@angular/core';
import {UserService} from '../../api/user.service';
import {ProfileService} from '../../api/profile.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { $ } from 'protractor';
import { PicklistService } from 'src/app/api/picklist.service';
import { MatSnackBar } from '@angular/material';
import { UtilService } from 'src/app/api/util.service';

@Component({
  selector: 'cup-profile-information',
  templateUrl: './profile-information.component.html',
  styleUrls: ['./profile-information.component.css']
})
export class ProfileInformationComponent implements OnInit {
  public profileSubscription: Subscription;
  private user: any;
  public profilePic = false;
  public titles: string[] = ['RN','APRN','PA','MD','OT'];
  public profile =
  {
    fname: "",
    mname: "",
    lname: "",
    title: "",
    cvTitle: "",
    npi: "",
    caqhId: "",
    addr1: "",
    addr2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
    obj: "",
    image: "",
    fileSource: ""
  };
  private picklistSubscription: Subscription;
  public picklist;
  public data: FormGroup;
  constructor(public profileService: ProfileService, public userService: UserService,public picklistService: PicklistService, public fbuilder: FormBuilder,
    public snackbar: MatSnackBar,private utilService: UtilService) {
    this.data = this.fbuilder.group({
      firstName: new FormControl(),
      middleName: new FormControl(),
      lastName: new FormControl(),
      title: new FormControl(),
      otTitle: new FormControl(),
      cvTitle: new FormControl(),
      npi: new FormControl(),
      caqhId: new FormControl(),
      address: new FormControl(),
      address2: new FormControl,
      city: new FormControl(),
      state: new FormControl(),
      zip: new FormControl(),
      phone: new FormControl(),
      email: new FormControl(),
      obj: new FormControl(),
      fileSource: new FormControl()
    });
  }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.profileService.getProfile(this.user);
    this.picklistService.getStates();
    this.picklistSubscription = this.picklistService.statesChanged.subscribe(states => {
      this.picklist = states.data;
    });
    this.profileSubscription = this.profileService.profileChanged.subscribe(profile =>{
      this.profile = profile.data;
      if(this.profile.fileSource != ""){
        document.getElementById("profileImg").setAttribute('src',this.profile.fileSource);
      }
      this.setFormGroup();
    });
  }
  ngOnDestroy(){
    this.profileSubscription.unsubscribe();
  }
  public setFormGroup(){
    var title = this.profile.title;
    if(this.titles.indexOf(title) == -1){
      title = "OT";
    }
    this.data = this.fbuilder.group({
      firstName: this.profile.fname,
      middleName: this.profile.mname,
      lastName: this.profile.lname,
      title: title,
      otTitle: this.profile.title,
      cvTitle: this.profile.cvTitle,
      npi: this.profile.npi,
      caqhId: this.profile.caqhId,
      address: this.profile.addr1,
      address2: this.profile.addr2,
      city: this.profile.city,
      state: this.profile.state,
      zip: this.profile.zip,
      phone: this.profile.phone,
      email: this.profile.email,
      obj: this.profile.obj,
      fileSource: ""
    });
  }
  public updateProfile(){
    var formData = new FormData();
    formData.append("fname", this.data.value.firstName);
    formData.append("mname", this.data.value.middleName);
    formData.append("lname", this.data.value.lastName);
    //if title is other set the value to user assigned value
    if(this.data.value.title != "OT"){
      formData.append("title", this.data.value.title);
    }else{
      formData.append("title", this.data.value.otTitle);
    }
    formData.append("cvtitle", this.data.value.cvTitle);
    formData.append("npi", this.data.value.npi);
    formData.append("caqhId", this.data.value.caqhId);
    formData.append("addr1", this.data.value.address);
    formData.append("addr2", this.data.value.address2);
    formData.append("city", this.data.value.city);
    formData.append("state", this.data.value.state);
    formData.append("zip", this.data.value.zip);
    formData.append("phone", this.data.value.phone);
    formData.append("email", this.data.value.email);
    formData.append("obj", this.data.value.obj);
    formData.append("fileSource", this.data.value.fileSource);
    this.profileService.updateProfile(this.user,formData);
    this.snackbar.open('Changes Saved','',{duration: 2000,panelClass: ['snackbar-success']});
  }
  public onFileChange(event) {
    const file = event.target.files[0];
    var check = this.utilService.checkFileExtention(file);
    if(check){
      this.data.patchValue({
        fileSource: file
      });
    }else{
      var myFile = document.getElementById('file') as HTMLInputElement;
      myFile.value = '';
      this.snackbar.open('File Type Not Accepted','',{duration: 2000,panelClass: ['snackbar-error']});
    }
  }
}
