import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private http: HttpClient) { }

  public notifications: any;
  public notificationsChanged = new Subject<any>();
  public messages: any;
  public messagesChanged = new Subject<any>();

  public getNotifications(userId: String){
    this.http.get(`/rest/checkExpiration.php?user=${userId}`).subscribe(data => {
      this.notifications = data;
      this.notificationsChanged.next(this.notifications);
    });
  }
  public getMessages(){
    this.http.get(`/rest/checkMessages.php`).subscribe(data => {
      this.messages = data;
      this.messagesChanged.next(this.messages);
    });
  }
}
