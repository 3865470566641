import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AdvertiseService } from 'src/app/api/advertise.service';
import { Advertisement } from 'src/app/models/advertisement.model';

@Component({
  selector: 'cup-advertise',
  templateUrl: './advertise.component.html',
  styleUrls: ['./advertise.component.css']
})
export class AdvertiseComponent implements OnInit {
  @Input() ads: Advertisement [];
  
  constructor() { }

  ngOnInit() {
  }
}