import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UtilService } from 'src/app/api/util.service';

@Component({
  selector: 'cup-update-insurance-dialog',
  templateUrl: './update-insurance-dialog.component.html',
  styleUrls: ['./update-insurance-dialog.component.css']
})
export class UpdateInsuranceDialogComponent implements OnInit {

  public record: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private utilService: UtilService, public dialogRef: MatDialogRef<UpdateInsuranceDialogComponent>,public fbuilder: FormBuilder) { }

  ngOnInit() {
    this.record = this.fbuilder.group({
      date: new FormControl(new Date(this.data.dateV)),
      exDate: new FormControl(new Date(this.data.dateE)),
      type: new FormControl(this.data.type),
      desc: new FormControl(this.data.desc)
    });
  }
  public update(){
    if(this.data.status == "INVALID"){
      alert("Please ensure all fields have been completed.");
    }else{
      var formData = new FormData();
      formData.append("id", this.data.id);
      formData.append("dateV", this.utilService.formatDate(this.record.value.date));
      formData.append("dateE", this.utilService.formatDate(this.record.value.exDate));
      formData.append("type", this.record.value.type);
      formData.append("desc", this.record.value.desc);
      this.dialogRef.close(formData);
    }
  }
  public cancel(){
    this.dialogRef.close(null);
  }
}
