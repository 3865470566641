import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { PicklistService } from 'src/app/api/picklist.service';
import { UtilService } from 'src/app/api/util.service';

@Component({
  selector: 'cup-update-licenses-dialog',
  templateUrl: './update-licenses-dialog.component.html',
  styleUrls: ['./update-licenses-dialog.component.css']
})
export class UpdateLicensesDialogComponent implements OnInit {

  public record: any;
  public states;
  private picklistSubscription: Subscription;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private utilService: UtilService, public dialogRef: MatDialogRef<UpdateLicensesDialogComponent>,public fbuilder: FormBuilder,public picklistService: PicklistService) { }

  ngOnInit() {
    this.record = this.fbuilder.group({
      licenseType:  new FormControl(this.data.type, Validators.required),
      licenseDesc:  new FormControl(this.data.desc, Validators.required),
      licenseNumber:  new FormControl(this.data.num, Validators.required),
      state:  new FormControl(this.data.state, Validators.required),
      picker:  new FormControl(new Date(this.data.i_Date), Validators.required),
      expiration:  new FormControl(new Date(this.data.e_Date), Validators.required),
      practicing:  new FormControl(this.data.prac, Validators.required)
    });
    //add call for state picklist
    this.picklistService.getStates();
    this.picklistSubscription = this.picklistService.statesChanged.subscribe(states => {
      this.states = states.data;
    });
  }
  ngOnDestroy(){
    this.picklistSubscription.unsubscribe();
  }
  public update(){
    if(this.data.status == "INVALID"){
      alert("Please ensure all fields have been completed.");
    }else{
      var formData = new FormData();
      formData.append("id", this.data.id);
      formData.append("i_Date", this.utilService.formatDate(this.record.value.picker));
      formData.append("e_Date", this.utilService.formatDate(this.record.value.expiration));
      formData.append("type", this.record.value.licenseType);
      formData.append("desc",this.record.value.licenseDesc);
      formData.append("num", this.record.value.licenseNumber);
      formData.append("state", this.record.value.state);
      formData.append("prac", this.record.value.practicing);
      this.dialogRef.close(formData);
    }
  }
  public cancel(){
    this.dialogRef.close(null);
  }
}
