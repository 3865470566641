import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';
import { PicklistService } from 'src/app/api/picklist.service';
import { UtilService } from 'src/app/api/util.service';

@Component({
  selector: 'cup-update-wh-dialog',
  templateUrl: './update-wh-dialog.component.html',
  styleUrls: ['./update-wh-dialog.component.css']
})
export class UpdateWhDialogComponent implements OnInit {
  public record: any;
  private picklistSubscription: Subscription;
  public picklist;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private utilService: UtilService, public dialogRef: MatDialogRef<UpdateWhDialogComponent>,public fbuilder: FormBuilder,public picklistService: PicklistService) { }

  ngOnInit() {
    this.record = this.fbuilder.group({
      date: new FormControl(new Date(this.data.dateS)),
      exDate: new FormControl(new Date(this.data.dateE)),
      employer: new FormControl(this.data.employ),
      address: new FormControl(this.data.addr),
      city: new FormControl(this.data.city),
      state: new FormControl(this.data.state),
      zip: new FormControl(this.data.zip),
      contact: new FormControl(this.data.contact),
      email: new FormControl(this.data.email),
      phone: new FormControl(this.data.phone)
    });
    this.picklistService.getStates();
    this.picklistSubscription = this.picklistService.statesChanged.subscribe(states => {
      this.picklist = states.data;
    });
  }
  ngOnDestroy(){
    this.picklistSubscription.unsubscribe();
  }
  public update(){
    if(this.data.status == "INVALID"){
      alert("Please ensure all fields have been completed.");
    }else{
      var formData = new FormData();
      formData.append("id", this.data.id);
      formData.append("dateS", this.utilService.formatDate(this.record.value.date));
      formData.append("dateE", this.utilService.formatDate(this.record.value.exDate));
      formData.append("employ", this.record.value.employer);
      formData.append("addr", this.record.value.address);
      formData.append("city", this.record.value.city);
      formData.append("state", this.record.value.state);
      formData.append("zip", this.record.value.zip);
      formData.append("contact", this.record.value.contact);
      formData.append("phone", this.record.value.phone);
      formData.append("email", this.record.value.email);
      this.dialogRef.close(formData);
    }
  }
  public cancel(){
    this.dialogRef.close(null);
  }
}
