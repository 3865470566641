import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'cup-demo-dialog',
  templateUrl: './demo-dialog.component.html',
  styleUrls: ['./demo-dialog.component.css']
})
export class DemoDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DemoDialogComponent>) { }

  ngOnInit() {
  }
  public close(){
    this.dialogRef.close();
  }
  public goToDemo(){
    var url = "https://www.cre-denseupload.com/signup/";
    window.open(url, "_blank");
    this.close();
  }
}
