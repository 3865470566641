import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/api/user.service';
import { WorkHistoryService } from 'src/app/api/work-history.service';
import { FileViewDialogComponent } from '../file-view-dialog/file-view-dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { PicklistService } from 'src/app/api/picklist.service';
import { DeleteService } from 'src/app/api/delete.service';
import { UpdateWhDialogComponent } from './update-wh-dialog/update-wh-dialog.component';
import { UtilService } from 'src/app/api/util.service';

@Component({
  selector: 'cup-work-history',
  templateUrl: './work-history.component.html',
  styleUrls: ['./work-history.component.css']
})
export class WorkHistoryComponent implements OnInit {
  public picker: any;
  public expicker: any;
  public data: any;
  public qual: any;
  private workHistorySubscrption: Subscription;
  private qualificationSubscrption: Subscription;
  private deleteSubscription: Subscription;
  private user;
  public workHistory: any[] = [];
  public qualifications: any[] = [];
  public profQualifications: any[] = [];
  private picklistSubscription: Subscription;
  public picklist;
  private saving = false;

  constructor(public fbuilder: FormBuilder, private userService: UserService, private workHistoryService: WorkHistoryService,public picklistService: PicklistService,
    private deleteService: DeleteService, public dialog: MatDialog,public snackbar: MatSnackBar,private utilService: UtilService) {
    this.data = this.fbuilder.group({
      date: new FormControl('', Validators.required),
      exDate: new FormControl('', Validators.required),
      employer: new FormControl('', Validators.required),
      address: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      zip: new FormControl(''),
      contact: new FormControl(''),
      email: new FormControl(''),
      phone: new FormControl()
    });
    this.qual = this.fbuilder.group({
      qualification: new FormControl('', Validators.required),
      type: new FormControl('', Validators.required)
    });
  }
  ngOnInit() {
    this.user = this.userService.getUser();
    this.workHistoryService.getWorkHistory(this.user);
    this.workHistoryService.getQualifications(this.user);
    this.picklistService.getStates();
    this.picklistSubscription = this.picklistService.statesChanged.subscribe(states => {
      this.picklist = states.data;
    });
    this.workHistorySubscrption = this.workHistoryService.workHistoryChanged.subscribe(work => {
      if(work.status === 'fail'){
        this.snackbar.open('Error inserting record','',{duration: 3000, panelClass: ['snackbar-error']});
      }else{
        this.workHistory = work.data;
        if(this.saving){
          this.clearForm('work');
          var sb = document.getElementById("save") as HTMLButtonElement;
          sb.disabled = false;
          this.snackbar.open('Work History Entry added','',{duration: 2000,panelClass: ['snackbar-success']});
        }
      }
    });
    this.qualificationSubscrption = this.workHistoryService.qualificationsChanged.subscribe(qual => {
      if(qual.status === 'fail'){
        this.snackbar.open('Error inserting record','',{duration: 3000, panelClass: ['snackbar-error']});
      }else{
        this.qualifications = qual.data.filter(type => type.type === "S");
        this.profQualifications = qual.data.filter(type => type.type === "P");
        if(this.saving){
          this.clearForm('qual');
          var sb = document.getElementById("save") as HTMLButtonElement;
          sb.disabled = false;
          this.snackbar.open('Qualification Entry added','',{duration: 2000,panelClass: ['snackbar-success']});
        }
      }
    });
    this.deleteSubscription = this.deleteService.valueChanged.subscribe(work =>{
      if(work != null){
        this.workHistory = work.data;
        this.snackbar.open('Entry Deleted','',{duration: 2000,panelClass: ['snackbar-success']});
      }
    });
  }
  ngOnDestroy(){
    this.workHistorySubscrption.unsubscribe();
    this.deleteSubscription.unsubscribe();
    this.picklistSubscription.unsubscribe();
  }
  public addWork(){
    if(this.data.status == "INVALID"){
      alert("Please ensure all fields have been completed.");
    }else{
      var formData = new FormData();
      formData.append("dateS", this.utilService.formatDate(this.data.value.date));
      formData.append("dateE", this.utilService.formatDate(this.data.value.exDate));
      formData.append("employ", this.data.value.employer);
      formData.append("addr", this.data.value.address);
      formData.append("city", this.data.value.city);
      formData.append("state", this.data.value.state);
      formData.append("zip", this.data.value.zip);
      formData.append("contact", this.data.value.contact);
      formData.append("phone", this.data.value.phone);
      formData.append("email", this.data.value.email);
      this.workHistoryService.addWorkHistory(this.user, formData);
      this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
      this.saving = true;
      var sb = document.getElementById("save") as HTMLButtonElement;
      sb.disabled = true;
    }
  }
  public addQualification(){
    if(this.qual.status == "INVALID"){
      alert("Please ensure all fields have been completed.");
    }else{
      var formData = new FormData();
      formData.append("desc", this.qual.value.qualification);
      formData.append("type", this.qual.value.type);
      this.workHistoryService.addQualifications(this.user, formData);
      this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
      this.saving = true;
      var sb = document.getElementById("save") as HTMLButtonElement;
      sb.disabled = true;
    }
  }
  public openEditDialog(record: any){
    var dialogRef = this.dialog.open(UpdateWhDialogComponent,{
      width: "750px",
      data: record
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined && result != null){
        this.workHistoryService.updateWorkHistory(this.user, result);
        this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
        this.saving = true;
      }
    });
  }
  public deleteWork(id: string, table: string){
    let del = confirm("Are you sure?");
    if(del){
      this.deleteService.deleteEntry(this.user,table,id);
    }
  }
  public resetForm(form: string){
    this.clearForm(form);
  }
  private clearForm(form: string){
    if(form == "qual"){
      this.qual.reset();
    }else{
      this.data.reset();
    }
  }
}
