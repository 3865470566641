import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { PageNotFoundComponent} from './page-not-found/page-not-found.component';
import { UserDashboardModule } from './user-dashboard/user-dashboard.module';
import { ViewPacketComponent } from './view-packet/view-packet.component';
import { FaqComponent } from './faq/faq.component';
import { CliniciansComponent } from './clinicians/clinicians.component';
import { EmployersComponent } from './employers/employers.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { NewsComponent } from './news/news.component';

const routes: Routes = [
  {path: "", component: LandingPageComponent},
  {path: "about-us", component: AboutUsComponent},
  {path: "contact-us", component: ContactUsComponent},
  {path: "view-packet", component: ViewPacketComponent},
  {path: "faq", component: FaqComponent},
  {path: "our-briefcase", component: CliniciansComponent},
  {path: "employers", component: EmployersComponent},
  {path: "sign-in", component: SignInComponent},
  {path: "news", component: NewsComponent},
  {path: 'user', loadChildren: () =>
    import("./user-dashboard/user-dashboard.module").then(m=> m.UserDashboardModule)
  },
  { path: 'package-purchase', loadChildren: () => 
    import('./package-purchase/package-purchase.module').then(m => m.PackagePurchaseModule) 
  },
  { path: '**', component: PageNotFoundComponent }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
