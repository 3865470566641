import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn  } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material';
import { TermsComponent } from 'src/app/terms/terms.component';
import { Subscription } from 'rxjs';
import { PicklistService } from 'src/app/api/picklist.service';
import { UserService } from 'src/app/api/user.service';
import { PrivacyComponent } from 'src/app/privacy/privacy.component';

@Component({
  selector: 'cup-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  public data: FormGroup;
  private picklistSubscription: Subscription;
  private userSubscription: Subscription;
  private emailSubscription: Subscription;
  public picklist;
  public emailExists = false;
  public userExists = false;
  constructor(public fbuilder: FormBuilder,public dialogRef: MatDialogRef<RegisterComponent>,public dialog: MatDialog,public picklistService: PicklistService, 
    private userService: UserService) { }

  ngOnInit() {
    this.data = this.fbuilder.group({
      username: new FormControl('',Validators.required),
      password: new FormControl('',[Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{12,}')]),
      confirmpassword: new FormControl('',Validators.required),
      first: new FormControl('',Validators.required),
      middle: '',
      last: new FormControl('',Validators.required),
      title: new FormControl('',Validators.required),
      email: new FormControl('',[Validators.required, Validators.email]),
      confirmemail: new FormControl('',Validators.required),
      terms: new FormControl(false,Validators.requiredTrue)
    },{validator: 
      Validators.compose(
        [this.matchPassword,this.matchEmail])
      });
    this.picklistService.getStates();
    this.picklistSubscription = this.picklistService.statesChanged.subscribe(states => {
      this.picklist = states.data;
    });
    this.userSubscription = this.userService.userExistsChanged.subscribe(user => {
      this.userExists = user.data;
    });
    this.emailSubscription = this.userService.emailExistsChanged.subscribe(email => {
      this.emailExists = email.data;
    });
  }
  public register(){
      if(!this.userExists && !this.emailExists){
        this.dialogRef.close(this.data.value);
        //alert("Registration is currently closed")
      }else{
        if(this.userExists){
          alert("Username already exists!");
        }else{
          alert("Email is already registered in system!");
        }
      }
  }
  public checkUsername(){
    this.userService.checkUsername(this.data.value.username);
  }
  public checkEmail(){
    this.userService.checkEmail(this.data.value.email);
  }
  public cancel(){
    this.dialogRef.close();
  }
  openTerms(){
    const dialogRef = this.dialog.open(TermsComponent, {
      width: '750px',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  privacyPolicy() {
    const dialogRef = this.dialog.open(PrivacyComponent, {
      width: '750px',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  matchPassword(control: AbstractControl): ValidationErrors | null {
 
    const password = control.get("password").value;
    const confirm = control.get("confirmpassword").value;
 
 
    if (password != confirm) { 
      control.get('confirmpassword').setErrors({'noMatch': true});
      return { 'noMatch': true } 
    }
    control.get('confirmpassword').setErrors(null);
    return null
 
  }
  matchEmail(control: AbstractControl): ValidationErrors | null {
 
    const email = control.get("email").value;
    const confirm = control.get("confirmemail").value;
 
 
    if (email != confirm) { 
      control.get('confirmemail').setErrors({'noMatch': true});
      return { 'noMatch': true } 
    }
    control.get('confirmemail').setErrors(null);
    return null
 
  }
}


