import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WorkHistoryService {
  public workHistory: any;
  public workHistoryChanged = new Subject<any>();
  public qualifcations: any;
  public qualificationsChanged = new Subject<any>();

  constructor(private http: HttpClient) { }
  public getWorkHistory(userId: String){
    this.http.get(`/rest/getWorks.php?user=${userId}`).subscribe(data => {
      this.workHistory = data;
      this.workHistoryChanged.next(this.workHistory);
    });
  }
  public addWorkHistory(user: any, data: any){
    this.http.post(`/rest/insertWorks.php?user=${user}`,data).subscribe(result => {
      this.workHistory = result;
      this.workHistoryChanged.next(this.workHistory);
    });
  }
  public updateWorkHistory(user: any, data: any){
    this.http.post(`/rest/updateWorks.php?user=${user}`,data).subscribe(result => {
      this.workHistory = result;
      this.workHistoryChanged.next(this.workHistory);
    });
  }
  public getQualifications(userId: String){
    this.http.get(`/rest/getQualifications.php?user=${userId}`).subscribe(data => {
      this.qualifcations = data;
      this.qualificationsChanged.next(this.qualifcations);
    });
  }
  public addQualifications(user: any, data: any){
    this.http.post(`/rest/insertQualifications.php?user=${user}`,data).subscribe(result => {
      this.qualifcations = result;
      this.qualificationsChanged.next(this.qualifcations);
    });
  }
}
