import { Component, HostListener, OnInit } from '@angular/core';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { UserService } from '../api/user.service';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { LocationStrategy } from '@angular/common';
@Component({
  selector: 'cup-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css']
})
export class UserDashboardComponent implements OnInit {
  private user: any;
  private userSubscription: Subscription;
  private userActivity;
  private userInactive: Subject<any> = new Subject();
  private passChange = false;
  constructor(public router: Router,private userService: UserService,public snackbar: MatSnackBar,public dialog: MatDialog,private location: LocationStrategy) { 
    this.setTimeout();
    this.userInactive.subscribe(() => this.logout('timeout'));
    history.pushState(null, null, window.location.href);  
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  ngOnInit() {
    this.user = this.userService.getUser();
    //Paypal is no handled in a different location.
    //this.loadExternalScripts('https://www.paypal.com/sdk/js?client-id=AecC1CvGx6eRXhx2eBk4tly9YL-i2NcjUHI6yNpAuZlcml7qGW385r1HzsVBhMeRQOrb9RcDsYmSfWYd&components=buttons,funding-eligibility,marks');
    this.userSubscription = this.userService.userChanged.subscribe(user =>{
      if(user.status === "success"){
        this.user = this.userService.getUser();
        if(this.passChange){
          this.snackbar.open('Password Changed','',{duration: 3000, panelClass: ['snackbar-success']});
        }
      }else{
        this.snackbar.open('Error Changing Password: ' + user.error,'',{duration: 3000, panelClass: ['snackbar-error']});
      }
    });
  }
  ngOnDestroy(){
    this.userSubscription.unsubscribe();
    this.userInactive.unsubscribe();
  }
  // private loadExternalScripts(scriptUrl: string) {
  //   return new Promise(resolve => {
  //     const scriptElement = document.createElement('script');
  //     scriptElement.src = scriptUrl;
  //     scriptElement.onload = resolve;
  //     document.body.appendChild(scriptElement);
  //   });
  // }
  public changePassword(){
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '700px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != null){
        this.userService.changePassword(this.user,result);
        this.passChange = true;
      }
    });
  }
  viewNotifications(){
    this.router.navigateByUrl('user/notifications');
  }
  viewPackets(){
    this.router.navigateByUrl('user/view-cancel');
  }
  assistanceForm(){
    this.router.navigateByUrl('user/assist');
  }
  cancelSubscription(){
    this.router.navigateByUrl('user/cancel-subscription');
  }
  logout(reason: string){
    if(reason === 'timeout'){
      this.snackbar.open('Logged out due to inactivity','',{duration: 3000, panelClass: ['snackbar-error']});
    }
    this.router.navigateByUrl('');
  }
  private setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 600000);
  }
  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
  @HostListener('document:keypress') refreshUserStateKey() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }
}
