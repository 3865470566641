import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FellowshipService {
  public fellowship: any;
  public fellowshipChanged = new Subject<any>();
  public fellowshipOth: any;
  public fellowshipOthChanged = new Subject<any>();
  constructor(private http: HttpClient) { }

  public insertFellowship(userId:String, data: any){
    this.http.post(`/rest/insertFellowships.php?user=${userId}`,data).subscribe(status => {
      this.fellowship= status;
      this.fellowshipChanged.next(this.fellowship);
    });
  }
  public updateFellowship(userId:String, data: any){
    this.http.post(`/rest/updateFellowships.php?user=${userId}`,data).subscribe(status => {
      this.fellowship= status;
      this.fellowshipChanged.next(this.fellowship);
    });
  }
  public getFellowship(userId: String){
    this.http.get(`/rest/getFellowships.php?user=${userId}`).subscribe(data => {
      this.fellowship = data;
      this.fellowshipChanged.next(this.fellowship);
    });
  }
  public insertFellowshipOth(userId:String, data: any){
    this.http.post(`/rest/insertFellowshipsOther.php?user=${userId}`,data).subscribe(status => {
      this.fellowshipOth= status;
      this.fellowshipOthChanged.next(this.fellowshipOth);
    });
  }
  public updateFellowshipOth(userId:String, data: any){
    this.http.post(`/rest/updateFellowshipsOther.php?user=${userId}`,data).subscribe(status => {
      this.fellowshipOth= status;
      this.fellowshipOthChanged.next(this.fellowshipOth);
    });
  }
  public getFellowshipOth(userId: String){
    this.http.get(`/rest/getFellowshipsOther.php?user=${userId}`).subscribe(data => {
      this.fellowshipOth = data;
      this.fellowshipOthChanged.next(this.fellowshipOth);
    });
  }
}
