import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UtilService } from 'src/app/api/util.service';
import { UpdateFellowshipDialogComponent } from '../update-fellowship-dialog/update-fellowship-dialog.component';

@Component({
  selector: 'cup-update-oth-fellowship-dialog',
  templateUrl: './update-oth-fellowship-dialog.component.html',
  styleUrls: ['./update-oth-fellowship-dialog.component.css']
})
export class UpdateOthFellowshipDialogComponent implements OnInit {

  public record: any;
  public fundingType: string [] = ['Scholarships','Fellowship Funding','Research Funding','Other'];
  public type: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private utilService: UtilService, public dialogRef: MatDialogRef<UpdateFellowshipDialogComponent>,public fbuilder: FormBuilder) { }

  ngOnInit() {
    if(this.data.type == 'f'){
      this.type = this.data.name;
      if(!this.fundingType.includes(this.type)){
        this.type = "Other";
      }
    }
    this.record= this.fbuilder.group({
      name: new FormControl(this.type),
      fType: new FormControl(this.data.name),
      description: new FormControl(this.data.desc, Validators.required)
    });
  }
  public update(){
    if(this.data.status == "INVALID"){
      alert("Please ensure all fields have been completed.");
    }else{
      var formData = new FormData();
      formData.append("id", this.data.id);
      if(this.data.type == 'f'){
        if(this.record.value.name === "Other"){
          formData.append("name", this.record.value.fType);
        }else{
          formData.append("name", this.record.value.name);
        }
      }
      formData.append("desc", this.record.value.description);
      this.dialogRef.close(formData);
    }
  }
  public cancel(){
    this.dialogRef.close(null);
  }

}
