import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/api/user.service';
import { ImmunizationsService } from 'src/app/api/immunizations.service';
import { Subscription } from 'rxjs';
import { FileViewDialogComponent } from '../file-view-dialog/file-view-dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { DeleteService } from 'src/app/api/delete.service';
import { UpdateImmunizationsDialogComponent } from './update-immunizations-dialog/update-immunizations-dialog.component';
import { UtilService } from 'src/app/api/util.service';

@Component({
  selector: 'cup-immunizations',
  templateUrl: './immunizations.component.html',
  styleUrls: ['./immunizations.component.css']
})
export class ImmunizationsComponent implements OnInit {
  public data: FormGroup;
  private immunizationSubscription: Subscription;
  private deleteSubscription: Subscription;
  public covidr: any[] = [];
  public covidat: any[] = [];
  public covidv: any[] = [];
  public titers: any[] = [];
  public flus: any [] = [];
  public ppds: any[] = [];
  public others: any[] = [];
  private user: any;
  private saving = false;
  constructor(public fbuilder: FormBuilder, private userService: UserService,  private immunizationsService: ImmunizationsService,
    private deleteService: DeleteService,public dialog: MatDialog,public snackbar: MatSnackBar,private utilService: UtilService) {
    this.data = this.fbuilder.group({
      date: new FormControl('', Validators.required),
      type: new FormControl('',Validators.required),
      description: new FormControl(''),
      fileSource: new FormControl()
    });
   }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.immunizationsService.getImmunizations(this.user);
    this.immunizationSubscription = this.immunizationsService.immunizationsChanged.subscribe(immunization => {
      this.covidr = immunization.data.filter(covid => covid.type === "C");
      this.covidat = immunization.data.filter(covid => covid.type === "A");
      this.covidv = immunization.data.filter(covid => covid.type === "V");
      this.titers = immunization.data.filter(titer => titer.type === "T");
      this.flus = immunization.data.filter(flu => flu.type === "F");
      this.ppds = immunization.data.filter(ppd => ppd.type === "P");
      this.others = immunization.data.filter(other => other.type === "O");
      if(this.saving){
        this.clearForm();
        var sb = document.getElementById("save") as HTMLButtonElement;
        sb.disabled = false;
        this.snackbar.open('Immunization Entry added','',{duration: 2000,panelClass: ['snackbar-success']});
      }
    });
    this.deleteSubscription = this.deleteService.valueChanged.subscribe(immunization =>{
      if(immunization!= null){
        if(immunization.status === 'fail'){
          this.snackbar.open('Error inserting record','',{duration: 3000, panelClass: ['snackbar-error']});
        }else{
          this.covidr = immunization.data.filter(covid => covid.type === "C");
          this.covidat = immunization.data.filter(covid => covid.type === "A");
          this.titers = immunization.data.filter(titer => titer.type === "T");
          this.flus = immunization.data.filter(flu => flu.type === "F");
          this.ppds = immunization.data.filter(ppd => ppd.type === "P");
          this.others = immunization.data.filter(other => other.type === "O");
          this.snackbar.open('Immunization Entry Deleted', '', { duration: 2000, panelClass: ['snackbar-success'] });
        }
      }
    });
  }
  ngOnDestroy(){
    this.immunizationSubscription.unsubscribe();
    this.deleteSubscription.unsubscribe();
  }
  public openDialog(file: any){
    var dialogRef = this.dialog.open(FileViewDialogComponent,{
      width: "1024px",
      data: file
    });
  }
  public onFileChange(event) {
    const file = event.target.files[0];
    var check = this.utilService.checkFileExtention(file);
    if(check){
      this.data.patchValue({
        fileSource: file
      });
    }else{
      var myFile = document.getElementById('file') as HTMLInputElement;
      myFile.value = '';
      this.snackbar.open('File Type Not Accepted','',{duration: 2000,panelClass: ['snackbar-error']});
    }
  }
  public addImmunization(){
    if(this.data.status == "INVALID"){
      this.snackbar.open('Please ensure all fields have been completed.','',{duration: 2000,panelClass: ['snackbar-error']});
    }else{
      if(this.data.value.fileSource != null){
        var formData = new FormData();
        formData.append("dte", this.utilService.formatDate(this.data.value.date));
        formData.append("type", this.data.value.type);
        formData.append("other", this.data.value.description);
        formData.append("fileSource", this.data.value.fileSource);
        this.immunizationsService.insertImmunization(this.user, formData);
        this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
        this.saving = true;
        var sb = document.getElementById("save") as HTMLButtonElement;
        sb.disabled = true;
      }else{
        this.snackbar.open('File Required','',{duration: 2000,panelClass: ['snackbar-error']});
      }
    }
  }
  public openEditDialog(record: any){
    var dialogRef = this.dialog.open(UpdateImmunizationsDialogComponent,{
      width: "500px",
      data: record
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined && result != null){
        this.immunizationsService.updateImmunization(this.user, result);
        this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
        this.saving = true;
      }
    });
  }
  public deleteImmunization(id: string){
    let del = confirm("Are you sure?");
    if(del){
      this.deleteService.deleteEntry(this.user,'T_IMMUN',id);
    }
  }
  public resetForm(){
    this.clearForm();
  }
  private clearForm(){
    var file = document.getElementById('file') as HTMLInputElement;
    file.value = '';
    this.data.reset();
  }
}
