import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { CreatelinkService } from 'src/app/api/createlink.service';
import { UserService } from 'src/app/api/user.service';

@Component({
  selector: 'cup-view-cancel',
  templateUrl: './view-cancel.component.html',
  styleUrls: ['./view-cancel.component.css']
})
export class ViewCancelComponent implements OnInit {
  private user: any;
  private createLinkSubscription: Subscription;
  public links: any;
  constructor(public userService: UserService,public createLinkService: CreatelinkService,public snackbar: MatSnackBar) { }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.createLinkService.getLinks(this.user);
    this.createLinkSubscription = this.createLinkService.linksChanged.subscribe(links => {
      this.links = links.data;
    });
  }
  ngOnDestroy(){
    this.createLinkSubscription.unsubscribe();
  }
  cancelPacket(id: any){
    let cancel = confirm("Are you sure?");
    if(cancel){
      this.createLinkService.cancelLink(this.user,id);
    }
  }
}
