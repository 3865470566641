import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OtherUploadsService {

  public otherUploads: any;
  public otherUploadsChanged = new Subject<any>();

  constructor(private http: HttpClient) { }
  public getOtherUploads(userId: String){
    this.http.get(`/rest/getOthers.php?user=${userId}`).subscribe(data => {
      this.otherUploads = data;
      this.otherUploadsChanged.next(this.otherUploads);
    });
  }
  public addOtherUpload(user: any, data: any){
    this.http.post(`/rest/insertOthers.php?user=${user}`,data).subscribe(result => {
      this.otherUploads = result;
      this.otherUploadsChanged.next(this.otherUploads);
    });
  }
  public updateOtherUpload(user: any, data: any){
    this.http.post(`/rest/updateOthers.php?user=${user}`,data).subscribe(result => {
      this.otherUploads = result;
      this.otherUploadsChanged.next(this.otherUploads);
    });
  }
}
