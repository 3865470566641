import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserDashboardRoutingModule } from './user-dashboard-routing.module';
import { UserDashboardComponent } from './user-dashboard.component';

import { ProfileInformationComponent } from './profile-information/profile-information.component';
import { PhysicalExamComponent } from './physical-exam/physical-exam.component';
import { ImmunizationsComponent } from './immunizations/immunizations.component';
import { InsuranceComponent } from './insurance/insurance.component';
import { EducationComponent } from './education/education.component';
import { FellowshipComponent } from './fellowship/fellowship.component';
import { LicensesComponent } from './licenses/licenses.component';
import { CertificationsComponent } from './certifications/certifications.component';
import { WorkHistoryComponent } from './work-history/work-history.component';
import { ContinuingEducationComponent } from './continuing-education/continuing-education.component';
import { CaselogsComponent } from './caselogs/caselogs.component';
import { ReferencesComponent } from './references/references.component';
import { OtherUploadsComponent } from './other-uploads/other-uploads.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { AppModule } from '../app.module';
import { MaterialModule } from '../material/material.module';
import { AddDialogComponent } from './licenses/add-dialog/add-dialog.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CreateLinkComponent } from './create-link/create-link.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { FileViewDialogComponent } from './file-view-dialog/file-view-dialog.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ViewCancelComponent } from './view-cancel/view-cancel.component';
import { UpdateCaselogDialogComponent } from './caselogs/update-caselog-dialog/update-caselog-dialog.component';
import { UpdateCertDialogComponent } from './certifications/update-cert-dialog/update-cert-dialog.component';
import { UpdateCeDialogComponent } from './continuing-education/update-ce-dialog/update-ce-dialog.component';
import { UpdateEducationDialogComponent } from './education/update-education-dialog/update-education-dialog.component';
import { UpdateFellowshipDialogComponent } from './fellowship/update-fellowship-dialog/update-fellowship-dialog.component';
import { UpdateImmunizationsDialogComponent } from './immunizations/update-immunizations-dialog/update-immunizations-dialog.component';
import { UpdateInsuranceDialogComponent } from './insurance/update-insurance-dialog/update-insurance-dialog.component';
import { UpdateLicensesDialogComponent } from './licenses/update-licenses-dialog/update-licenses-dialog.component';
import { UpdateOtherDialogComponent } from './other-uploads/update-other-dialog/update-other-dialog.component';
import { UpdatePhysicalDialogComponent } from './physical-exam/update-physical-dialog/update-physical-dialog.component';
import { UpdateReferenceDialogComponent } from './references/update-reference-dialog/update-reference-dialog.component';
import { UpdateWhDialogComponent } from './work-history/update-wh-dialog/update-wh-dialog.component';
import { LegalLinkDialogComponent } from './create-link/legal-link-dialog/legal-link-dialog.component';
import { AddDocumentDialogComponent } from './add-document-dialog/add-document-dialog.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { CancelSubscriptionComponent } from './cancel-subscription/cancel-subscription.component';
import { UpdateOthFellowshipDialogComponent } from './fellowship/update-oth-fellowship-dialog/update-oth-fellowship-dialog.component';

@NgModule({
  declarations: [
    UserDashboardComponent,
    ProfileInformationComponent,
    PhysicalExamComponent,
    ImmunizationsComponent,
    InsuranceComponent,
    EducationComponent,
    FellowshipComponent,
    LicensesComponent,
    CertificationsComponent,
    WorkHistoryComponent,
    ContinuingEducationComponent,
    CaselogsComponent,
    ReferencesComponent,
    OtherUploadsComponent,
    SideBarComponent,
    AddDialogComponent,
    CreateLinkComponent,
    ConfirmationComponent,
    FileViewDialogComponent,
    ChangePasswordComponent,
    NotificationsComponent,
    ViewCancelComponent,
    UpdateCaselogDialogComponent,
    UpdateCertDialogComponent,
    UpdateCeDialogComponent,
    UpdateEducationDialogComponent,
    UpdateFellowshipDialogComponent,
    UpdateImmunizationsDialogComponent,
    UpdateInsuranceDialogComponent,
    UpdateLicensesDialogComponent,
    UpdateOtherDialogComponent,
    UpdatePhysicalDialogComponent,
    UpdateReferenceDialogComponent,
    UpdateWhDialogComponent,
    LegalLinkDialogComponent,
    AddDocumentDialogComponent,
    ContactFormComponent,
    CancelSubscriptionComponent,
    UpdateOthFellowshipDialogComponent
  ],
  imports: [
    CommonModule,
    UserDashboardRoutingModule,
    MaterialModule,
    ReactiveFormsModule
  ],
  entryComponents: [ProfileInformationComponent, PhysicalExamComponent, ImmunizationsComponent, InsuranceComponent, EducationComponent, FellowshipComponent, LicensesComponent, CertificationsComponent, WorkHistoryComponent, ContinuingEducationComponent, CaselogsComponent, ReferencesComponent, OtherUploadsComponent, AddDialogComponent, CreateLinkComponent,FileViewDialogComponent, ChangePasswordComponent, NotificationsComponent, ViewCancelComponent,UpdateCaselogDialogComponent, UpdateCertDialogComponent, UpdateCeDialogComponent, UpdateEducationDialogComponent, UpdateFellowshipDialogComponent, UpdateImmunizationsDialogComponent, UpdateInsuranceDialogComponent, UpdateLicensesDialogComponent, UpdateOtherDialogComponent, UpdatePhysicalDialogComponent, UpdateReferenceDialogComponent, UpdateWhDialogComponent,LegalLinkDialogComponent, AddDocumentDialogComponent, ContactFormComponent, CancelSubscriptionComponent, UpdateOthFellowshipDialogComponent]
})
export class UserDashboardModule { }
