import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UtilService } from 'src/app/api/util.service';

@Component({
  selector: 'cup-update-fellowship-dialog',
  templateUrl: './update-fellowship-dialog.component.html',
  styleUrls: ['./update-fellowship-dialog.component.css']
})
export class UpdateFellowshipDialogComponent implements OnInit {
  public record: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private utilService: UtilService, public dialogRef: MatDialogRef<UpdateFellowshipDialogComponent>,public fbuilder: FormBuilder) { }

  ngOnInit() {
    this.record= this.fbuilder.group({
      date: new FormControl(new Date(this.data.yearB), Validators.required),
      exDate: new FormControl(new Date(this.data.yearE), Validators.required),
      name: new FormControl(this.data.name, Validators.required),
      description: new FormControl(this.data.desc, Validators.required)
    });
  }
  public update(){
    if(this.data.status == "INVALID"){
      alert("Please ensure all fields have been completed.");
    }else{
      var formData = new FormData();
      formData.append("id", this.data.id);
      formData.append("yearB", this.utilService.formatDate(this.record.value.date));
      formData.append("yearE", this.utilService.formatDate(this.record.value.exDate));
      formData.append("name", this.record.value.name);
      formData.append("desc", this.record.value.description);
      this.dialogRef.close(formData);
    }
  }
  public cancel(){
    this.dialogRef.close(null);
  }
}
