import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/api/user.service';

@Component({
  selector: 'cup-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  public data: any;
  public userSubscription = new Subscription;
  constructor(public dialogRef: MatDialogRef<ResetPasswordComponent>,public fbuilder: FormBuilder,private userService: UserService,public snackbar: MatSnackBar) { 
    this.data = this.fbuilder.group({
      first: new FormControl('',Validators.required),
      last: new FormControl('',Validators.required),
      email: new FormControl('',[Validators.required, Validators.email])
    });
  }

  ngOnInit() {
    this.userSubscription = this.userService.usernameChanged.subscribe(user => {
      if(user.status != "error"){
        this.snackbar.open('Password Reset Instructions have been sent to email.','',{duration: 5000, panelClass: ['snackbar-success']});
      }else{
        this.snackbar.open('No User Found','Information did not match any records',{duration: 3000, panelClass: ['snackbar-error']});
      }
    });
  }
  ngOnDestroy(){
    this.userSubscription.unsubscribe();
  }
  public reset(){
    if(this.data.status == "INVALID"){
      alert("Please ensure all fields have been completed.");
    }else{
      var formData = new FormData();
      formData.append("first", this.data.value.first);
      formData.append("last", this.data.value.last);
      formData.append("email", this.data.value.email);
      this.userService.resetPassword(formData);
    }
  }
  public cancel(){
    this.dialogRef.close();
  }
}
