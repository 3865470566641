import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'cup-file-view-dialog',
  templateUrl: './file-view-dialog.component.html',
  styleUrls: ['./file-view-dialog.component.css']
})
export class FileViewDialogComponent implements OnInit {
  public isString = false;
  public image: any;
  public images = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<FileViewDialogComponent>
  ) { }
  ngOnInit(){
    if(typeof this.data == "string"){
      this.image =this.data;
      this.isString = true;
    }else{
      for(var i = 0;i < this.data.length; i++){
        this.images[i]=this.data[i];
      }
    }
  }
  public close(){
    this.dialogRef.close();
  }
}
