import { Component, OnInit, ViewChild } from '@angular/core';
import { MatExpansionPanelHeader } from '@angular/material';
import { Subscription } from 'rxjs';
import { AdvertiseService } from '../api/advertise.service';

@Component({
  selector: 'cup-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  @ViewChild('clinicianPanel', {static: true}) clinicianPanel: MatExpansionPanelHeader;
  @ViewChild('employerPanel', {static: true}) employerPanel: MatExpansionPanelHeader;
  public adsSubscription = new Subscription();
  public ads = [];
  constructor(private advertiseService: AdvertiseService) { }

  ngOnInit() {
    this.advertiseService.getAdvertisements('F');
    this.adsSubscription = this.advertiseService.adChanged.subscribe(ads => {
     this.ads = ads.data;
    });
  }
  ngOnDestroy() {
    this.adsSubscription.unsubscribe();
  }
}
