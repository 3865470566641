import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { PacketService } from '../api/packet.service';
import { Subscription } from 'rxjs';
import { MatDialog, MatSnackBar } from '@angular/material';
import { jsPDF } from 'jspdf';
import { LegalDialogComponent } from './legal-dialog/legal-dialog.component';

@Component({
  selector: 'cup-view-packet',
  templateUrl: './view-packet.component.html',
  styleUrls: ['./view-packet.component.css']
})
export class ViewPacketComponent implements OnInit {
  public data: FormGroup;
  public packetSubscription = new Subscription;
  public packets: any;
  public packetEntered = false;
  public profile: any;
  public underGrad: any[];
  public grad: any[];
  public postGrad: any[];
  public internships: any[];
  public residencies: any[];
  public licenses: any[];
  public deaLicenses: any[];
  public alcsList: any[];
  public palsList: any[];
  public blsList: any[];
  public nrpList: any[];
  public traumaList: any[];
  public otherList: any[];
  public memberList: any[];
  public workHistory: any[];
  public references: any[];
  public attachments: any[];
  public fellowships: any[];
  public funding: any[];
  public fundingType: string [] = ['Scholarships','Fellowship Funding','Research Funding','Other'];
  public scholarships: any[];
  public fellowshipFunding: any[];
  public researchFunding: any[];
  public otherFunding: any[];
  public awards: any[];
  public pubs: any[];
  public presents: any[];
  public volunteer: any[];
  public qualifications: any[];
  public profQual: any[];
  public includeCV: any;

  constructor(public packetService: PacketService,public fbuilder: FormBuilder,public snackbar: MatSnackBar,public dialog: MatDialog) { }
  
  ngOnInit() {
    this.data = this.fbuilder.group({
      name: new FormControl,
      code: new FormControl
    });
    this.packetSubscription = this.packetService.packetChanged.subscribe(packet => {
      if(packet.status != "fail"){
        this.packets = packet.data;
        this.profile = packet.data.profile;
        this.includeCV = packet.data.cv;
        this.underGrad = packet.data.education.filter(ug => ug.type === "U");
        this.grad = packet.data.education.filter(g => g.type === "G");
        this.postGrad = packet.data.education.filter(pg => pg.type === "P");
        this.internships = packet.data.education.filter(pg => pg.type === "I");
        this.residencies = packet.data.education.filter(pg => pg.type === "R");
        this.licenses = packet.data.licenses.filter(type => type.type === "R");
        this.deaLicenses = packet.data.licenses.filter(type => type.type === "D");
        this.alcsList = packet.data.certifications.filter(alcs => alcs.type === "A");
        this.palsList = packet.data.certifications.filter(pals => pals.type === "P");
        this.blsList = packet.data.certifications.filter(bls => bls.type === "B");
        this.nrpList = packet.data.certifications.filter(nrp => nrp.type === "N");
        this.memberList = packet.data.certifications.filter(trauma => trauma.type === "M");
        this.traumaList = packet.data.certifications.filter(trauma => trauma.type === "T");
        this.otherList = packet.data.certifications.filter(other => other.type === "O");
        this.workHistory = packet.data.workHistory;
        this.references = packet.data.references;
        this.fellowships = packet.data.fellowships;
        this.funding = packet.data.fellowshipsOth.filter(other => other.type === "f");
        this.fellowshipFunding = this.funding.filter(other => other.name === "Fellowship Funding");
        this.scholarships = this.funding.filter(other => other.name === "Scholarships");
        this.researchFunding = this.funding.filter(other => other.name === "Research Funding");
        this.otherFunding = this.funding.filter(other => {
          return !this.fundingType.find(fund => {
             return fund === other.name;
          })});
        this.awards = packet.data.fellowshipsOth.filter(other => other.type === "a");
        this.pubs = packet.data.fellowshipsOth.filter(other => other.type === "p");
        this.presents = packet.data.fellowshipsOth.filter(other => other.type === "r");
        this.volunteer = packet.data.fellowshipsOth.filter(other => other.type === "v");
        this.qualifications = packet.data.qualifications.filter(other => other.type === "S");
        this.profQual = packet.data.qualifications.filter(other => other.type === "P");
        this.attachments = packet.data.attachments;
        this.packetEntered = true;
        this.snackbar.open('Retrieval Successful','',{duration: 3000, panelClass: ['snackbar-success']});
        this.buildPacket();
        
        
      }else{
        this.snackbar.open('Unable to retrieve packet: ' + packet.error,'',{duration: 3000, panelClass: ['snackbar-error']});
      }
    });
  }
  ngOnDestroy(){
    this.packetSubscription.unsubscribe();
  }
  getPacket(){
    var dialogRef = this.dialog.open(LegalDialogComponent,{
      width: "300px"
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.packetService.getPacket(this.data.value.name,this.data.value.code);
      }
    });
  }
  buildPacket(){
    var pdf = new jsPDF('p','px',[612,792]);
    var onNew = true;
    if(this.includeCV === "Y"){
      onNew = false;
      var profileName = this.profile.fname + " " + this.profile.lname;
      if(this.profile.cvtitle != null && this.profile.cvtitle != 'null'){
        profileName +=  ", " + this.profile.cvtitle;
      }
      var profileAddress = this.profile.addr1;
      var profileAddress2 = this.profile.addr2;
      var cityStateZip = this.profile.city + " " + this.profile.state + ", " + this.profile.zip;
      pdf.setFont('arial','bold');
      pdf.text(profileName,pdf.internal.pageSize.width/2,15,null, 'center');
      pdf.setFont('arial','normal');
      pdf.text(profileAddress,pdf.internal.pageSize.width/2,30,null, 'center');
      var start = 45;
      if(profileAddress2 != ''){
        pdf.text(profileAddress,pdf.internal.pageSize.width/2,start,null, 'center');
        start +=15;
      }
      pdf.text(cityStateZip,pdf.internal.pageSize.width/2,start,null, 'center');
      start +=15;
      pdf.text(this.profile.phone,pdf.internal.pageSize.width/2,start,null, 'center');
      start +=15;
      pdf.text(this.profile.email,pdf.internal.pageSize.width/2,start,null, 'center');
      start += 25;
      if(this.profile.obj != undefined){    
        pdf.setFont('arial','bold');
        pdf.text('Professional Summary',pdf.internal.pageSize.width/2,start,null, 'center');
        start +=5;
        pdf.text('__________________________________________________________________________________',pdf.internal.pageSize.width/2,start,null, 'center');
        start +=25;
        pdf.setFont('arial','normal');
        var profSummary = this.profile.obj;
        pdf.text(profSummary, 60, start);
        start +=25;
      }
      if(this.profile.npi != undefined || this.profile.caqhId != undefined){
        pdf.setFont('arial','bold');
        pdf.text('National Identifiers',60,start);
        pdf.setFont('arial','normal');
        start +=25;
      }
      if(this.profile.npi != undefined){
        var npi = "NPI: " + this.profile.npi;
        pdf.text(npi, 80, start);
        start +=25;
      }
      if(this.profile.caqhId != undefined){    
        var caqh = "CAQH ID: " + this.profile.caqhId;
        pdf.text(caqh, 80, start);
        start +=25;
      }
      
      
      if(this.postGrad.length > 0 || this.grad.length > 0 || this.underGrad.length > 0){
        pdf.setFont('arial','bold');
        pdf.text('Education',60,start);
        pdf.setFont('arial','normal');
        start +=25;
        if(this.postGrad.length > 0){
          for(var i = 0; i < this.postGrad.length;i++){
            var text1 = this.postGrad[i].name + ", " + this.postGrad[i].yearE;
            var addr = this.postGrad[i].addr;
            var csz = this.postGrad[i].city + ", " + this.postGrad[i].state + " " + this.postGrad[i].zip;
            var text2 = this.postGrad[i].degree;
            if(start >= 700){
              pdf.addPage();
              start=15;
            }
            pdf.text(text1,80,start);
            start += 15;
            pdf.text(addr,80,start);
            start += 15;
            pdf.text(csz,80,start);
            start += 15;
            pdf.text(text2,80,start);
            start +=25;
          }
        }
        if(this.grad.length > 0){
          for(var i = 0; i < this.grad.length;i++){
            var text1 = this.grad[i].name + ", " + this.grad[i].yearE;
            var addr = this.grad[i].addr;
            var csz = this.grad[i].city + ", " + this.grad[i].state + " " + this.grad[i].zip;
            var text2 = this.grad[i].degree;
            if(start >= 700){
              pdf.addPage();
              start=15;
            }
            pdf.text(text1,80,start);
            start += 15;
            pdf.text(addr,80,start);
            start += 15;
            pdf.text(csz,80,start);
            start += 15;
            pdf.text(text2,80,start);
            start +=25;
          }
        }
        if(this.underGrad.length > 0){
          for(var i = 0; i < this.underGrad.length;i++){
            var text1 = this.underGrad[i].name + ", " + this.underGrad[i].yearE;
            var addr = this.underGrad[i].addr;
            var csz = this.underGrad[i].city + ", " + this.underGrad[i].state + " " + this.underGrad[i].zip;
            var text2 = this.underGrad[i].degree;
            if(start >= 700){
              pdf.addPage();
              start=15;
            }
            pdf.text(text1,80,start);
            start += 15;
            pdf.text(addr,80,start);
            start += 15;
            pdf.text(csz,80,start);
            start += 15;
            pdf.text(text2,80,start);
            start +=25;
          }
        }
      }
      if(this.internships.length > 0){
        pdf.setFont('arial','bold');
        pdf.text('Internships',60,start);
        pdf.setFont('arial','normal');
        start +=25;
        if(start >= 700){
          pdf.addPage();
          start=15;
        }
        if(this.internships.length > 0){
          for(var i = 0; i < this.internships.length;i++){
            var text1 = this.internships[i].name + ", " + this.internships[i].yearE;
            var addr = this.internships[i].addr;
            var csz = this.internships[i].city + ", " + this.internships[i].state + " " + this.internships[i].zip;
            var text2 = this.internships[i].degree;
            if(start >= 700){
              pdf.addPage();
              start=15;
            }
            pdf.text(text1,80,start);
            start += 15;
            pdf.text(addr,80,start);
            start += 15;
            pdf.text(csz,80,start);
            start += 15;
            pdf.text(text2,80,start);
            start +=25;
          }
        }
      }
      if(this.residencies.length > 0){
        pdf.setFont('arial','bold');
        pdf.text('Residencies',60,start);
        pdf.setFont('arial','normal');
        start +=25;
        if(start >= 700){
          pdf.addPage();
          start=15;
        }
        if(this.residencies.length > 0){
          for(var i = 0; i < this.residencies.length;i++){
            var text1 = this.residencies[i].name + ", " + this.residencies[i].yearE;
            var addr = this.residencies[i].addr;
            var csz = this.residencies[i].city + ", " + this.residencies[i].state + " " + this.residencies[i].zip;
            var text2 = this.residencies[i].degree;
            if(start >= 700){
              pdf.addPage();
              start=15;
            }
            pdf.text(text1,80,start);
            start += 15;
            pdf.text(addr,80,start);
            start += 15;
            pdf.text(csz,80,start);
            start += 15;
            pdf.text(text2,80,start);
            start +=25;
          }
        }
      }
      if(this.fellowships.length > 0){
        pdf.setFont('arial','bold');
        if(this.profile.title == "RN"){
          pdf.text('Specialized Training',60,start);
        }else{
          pdf.text('Fellowships',60,start);
        }
      
        pdf.setFont('arial','normal');
        start +=25;
        if(start >= 700){
          pdf.addPage();
          start=15;
        }
        for(var i = 0; i < this.fellowships.length;i++){
          var text1 = this.fellowships[i].name + ", " + this.fellowships[i].yearB + " - " + this.fellowships[i].yearE;
          pdf.text(text1,80,start);
          start +=25;
          if(start >= 700){
            pdf.addPage();
            start=15;
          }
        }
      }
      if(this.workHistory.length > 0){
        pdf.setFont('arial','bold');
        pdf.text('Work History',60,start);
        pdf.setFont('arial','normal');
        start +=25;
        if(start >= 700){
          pdf.addPage();
          start=15;
        }
        for(var i = 0; i < this.workHistory.length;i++){
          var text1 = this.workHistory[i].employ + " (" + this.workHistory[i].dateS + " - " + this.workHistory[i].dateE + ")";
          var t2 = this.workHistory[i].addr + ", " + this.workHistory[i].city + ", " + this.workHistory[i].state + " " + this.workHistory[i].zip;
          var t3 = "Contact: " + this.workHistory[i].contact + " - " + this.workHistory[i].phone;
          pdf.text(text1,80,start);
          start += 15;
          pdf.text(t2,80,start);
          start += 15;
          pdf.text(t3,80,start);
          start +=25;
          if(start >= 700){
            pdf.addPage();
            start=15;
          }
        }
      }
      if(this.licenses.length > 0){
        pdf.setFont('arial','bold');
        pdf.text('Licenses',60,start);
        pdf.setFont('arial','normal');
        start +=25;
        if(start >= 700){
          pdf.addPage();
          start=15;
        }
        for(var i = 0; i < this.licenses.length;i++){
          var text1 = this.licenses[i].desc + ", " + this.licenses[i].state + ", " + this.licenses[i].num + ", " + this.licenses[i].e_Date;
          pdf.text(text1,80,start);
          start +=25;
          if(start >= 700){
            pdf.addPage();
            start=15;
          }
        }
      }
      if(this.deaLicenses.length > 0){
        pdf.setFont('arial','bold');
        pdf.text('DEA Licenses',60,start);
        pdf.setFont('arial','normal');
        start +=25;
        if(start >= 700){
          pdf.addPage();
          start=15;
        }
        for(var i = 0; i < this.deaLicenses.length;i++){
          var text1 = this.deaLicenses[i].desc + ", " + this.deaLicenses[i].state + ", " + this.deaLicenses[i].num + ", " + this.deaLicenses[i].e_Date;
          pdf.text(text1,80,start);
          start +=25;
          if(start >= 700){
            pdf.addPage();
            start=15;
          }
        }
      }
      if(this.profQual.length > 0){
        pdf.setFont('arial','bold');
        pdf.text('Professional Qualifications',60,start);
      
        pdf.setFont('arial','normal');
        start +=25;
        if(start >= 700){
          pdf.addPage();
          start=15;
        }
        for(var i = 0; i < this.profQual.length;i++){
          var t1 = this.profQual[i].desc;
          pdf.text(t1,80,start);
          start +=25;
          if(start >= 700){
            pdf.addPage();
            start=15;
          }
        }
      }
      if(this.qualifications.length > 0){
        pdf.setFont('arial','bold');
        pdf.text('Skills',60,start);
        pdf.setFont('arial','normal');
        start +=25;
        if(this.qualifications.length > 0){
          for(var i = 0; i < this.qualifications.length;i++){
            var qual = this.qualifications[i].desc;
            if(start >= 700){
              pdf.addPage();
              start=15;
            }
            pdf.text(qual,80,start);
            if((this.qualifications.length -1) > i){
              i++;
              var qual2 = this.qualifications[i].desc;
              pdf.text(qual2,200,start);
              if((this.qualifications.length -1) > i){
                i++;
                var qual3 = this.qualifications[i].desc;
                pdf.text(qual3,320,start);
              }
            }
            start +=25;
          }
        }
      }
      if(this.memberList.length > 0){
        pdf.setFont('arial','bold');
        pdf.text('Professional Memberships',60,start);
      
        pdf.setFont('arial','normal');
        start +=25;
        if(start >= 700){
          pdf.addPage();
          start=15;
        }
        for(var i = 0; i < this.memberList.length;i++){
          var text1 = this.memberList[i].spcl + " - " + this.memberList[i].i_Date + " - " + this.memberList[i].e_Date;
          var text2 = this.memberList[i].agency;
          pdf.text(text1,80,start);
          start += 15;
          pdf.text(text2,80,start);
          start +=25;
          if(start >= 700){
            pdf.addPage();
            start=15;
          }
        }
      }
      if(this.volunteer.length > 0){
        pdf.setFont('arial','bold');
        pdf.text('Volunteering',60,start);
      
        pdf.setFont('arial','normal');
        start +=25;
        if(start >= 700){
          pdf.addPage();
          start=15;
        }
        for(var i = 0; i < this.volunteer.length;i++){
          var text2 = this.volunteer[i].desc;
          pdf.text(text2,80,start);
          start +=25;
          if(start >= 700){
            pdf.addPage();
            start=15;
          }
        }
      }
      if(this.funding.length > 0){
        pdf.setFont('arial','bold');
        pdf.text('Funding Awarded',60,start);
        pdf.setFont('arial','normal');
        start +=25;
        if(start >= 700){
          pdf.addPage();
          start=15;
        }
        if(this.scholarships.length > 0){
          pdf.setFont('arial','bold');
          pdf.text('Scholarships',70,start);
          pdf.setFont('arial','normal');
          start +=25;
          if(start >= 700){
            pdf.addPage();
            start=15;
          }
          for(var i = 0; i < this.scholarships.length;i++){
            var text2 = this.scholarships[i].desc;
            pdf.text(text2,80,start);
            start +=25;
            if(start >= 700){
              pdf.addPage();
              start=15;
            }
          }
        }
        if(this.fellowshipFunding.length > 0){
          pdf.setFont('arial','bold');
          pdf.text('Fellowship Funding',70,start);
          pdf.setFont('arial','normal');
          start +=25;
          if(start >= 700){
            pdf.addPage();
            start=15;
          }
          for(var i = 0; i < this.fellowshipFunding.length;i++){
            var text2 = this.fellowshipFunding[i].desc;
            pdf.text(text2,80,start);
            start +=25;
            if(start >= 700){
              pdf.addPage();
              start=15;
            }
          }
        }
        if(this.researchFunding.length > 0){
          pdf.setFont('arial','bold');
          pdf.text('Research Funding',70,start);
          pdf.setFont('arial','normal');
          start +=25;
          if(start >= 700){
            pdf.addPage();
            start=15;
          }
          for(var i = 0; i < this.researchFunding.length;i++){
            var text2 = this.researchFunding[i].desc;
            pdf.text(text2,80,start);
            start +=25;
            if(start >= 700){
              pdf.addPage();
              start=15;
            }
          }
        }
        if(this.otherFunding.length > 0){
          pdf.setFont('arial','bold');
          pdf.text('Other Funding',70,start);
          pdf.setFont('arial','normal');
          start +=25;
          if(start >= 700){
            pdf.addPage();
            start=15;
          }
          for(var i = 0; i < this.otherFunding.length;i++){
            var text2 = this.otherFunding[i].desc;
            pdf.text(text2,80,start);
            start +=25;
            if(start >= 700){
              pdf.addPage();
              start=15;
            }
          }
        }
      }
      if(this.awards.length > 0){
        pdf.setFont('arial','bold');
        pdf.text('Awards',60,start);
      
        pdf.setFont('arial','normal');
        start +=25;
        if(start >= 700){
          pdf.addPage();
          start=15;
        }
        for(var i = 0; i < this.awards.length;i++){
          var text2 = this.awards[i].desc;
          pdf.text(text2,80,start);
          start +=25;
          if(start >= 700){
            pdf.addPage();
            start=15;
          }
        }
      }
      if(this.pubs.length > 0){
        pdf.setFont('arial','bold');
        pdf.text('Publications',60,start);
      
        pdf.setFont('arial','normal');
        start +=25;
        if(start >= 700){
          pdf.addPage();
          start=15;
        }
        for(var i = 0; i < this.pubs.length;i++){
          var text2 = this.pubs[i].desc;
          pdf.text(text2,80,start);
          start +=25;
          if(start >= 700){
            pdf.addPage();
            start=15;
          }
        }
      }
      if(this.presents.length > 0){
        pdf.setFont('arial','bold');
        pdf.text('Presentations',60,start);
        pdf.setFont('arial','normal');
        start +=25;
        if(start >= 700){
          pdf.addPage();
          start=15;
        }
        for(var i = 0; i < this.presents.length;i++){
          var text2 = this.presents[i].desc;
          pdf.text(text2,80,start);
          start +=25;
          if(start >= 700){
            pdf.addPage();
            start=15;
          }
        }
      }
      if(this.alcsList.length > 0 || this.palsList.length > 0 || this.blsList.length > 0 || this.nrpList.length > 0 || this.traumaList.length > 0 || this.otherList.length > 0){
        pdf.setFont('arial','bold');
        pdf.text('Certifications',60,start);
        pdf.setFont('arial','normal');
        start +=25;
        if(start >= 700){
          pdf.addPage();
          start=15;
        }
        if(this.alcsList.length > 0){
          for(var i = 0; i < this.alcsList.length;i++){
            var text1 = "ALCS, " + this.alcsList[i].spcl + ", " + this.alcsList[i].agency + ", " + this.alcsList[i].e_Date;
            pdf.text(text1,80,start);
            start +=25;
            if(start >= 700){
              pdf.addPage();
              start=15;
            }
          }
        }
        if(this.palsList.length > 0){
          for(var i = 0; i < this.palsList.length;i++){
            var text1 = "PALS, " + this.palsList[i].spcl + ", " + this.palsList[i].agency + ", " + this.palsList[i].e_Date;
            pdf.text(text1,80,start);
            start +=25;
            if(start >= 700){
              pdf.addPage();
              start=15;
            }
          }
        }
        if(this.blsList.length > 0){
          for(var i = 0; i < this.blsList.length;i++){
            var text1 = "BLS, " + this.blsList[i].spcl + ", " + this.blsList[i].agency + ", " + this.blsList[i].e_Date;
            pdf.text(text1,80,start);
            start +=25;
            if(start >= 700){
              pdf.addPage();
              start=15;
            }
          }
        }
        if(this.nrpList.length > 0){
          for(var i = 0; i < this.nrpList.length;i++){
            var text1 = "NRP, " + this.nrpList[i].spcl + ", " + this.nrpList[i].agency + ", " + this.nrpList[i].e_Date;
            pdf.text(text1,80,start);
            start +=25;
            if(start >= 700){
              pdf.addPage();
              start=15;
            }
          }
        }
        if(this.traumaList.length > 0){
          for(var i = 0; i < this.traumaList.length;i++){
            var text1 = "Trauma, " + this.traumaList[i].spcl + ", " + this.traumaList[i].agency + ", " + this.traumaList[i].e_Date;
            pdf.text(text1,80,start);
            start +=25;
            if(start >= 700){
              pdf.addPage();
              start=15;
            }
          }
        }
        if(this.otherList.length > 0){
          for(var i = 0; i < this.otherList.length;i++){
            var text1 = "Other " + this.otherList[i].spcl + ", " + this.otherList[i].agency + ", " + this.otherList[i].e_Date;
            pdf.text(text1,80,start);
            start +=25;
            if(start >= 700){
              pdf.addPage();
              start=15;
            }
          }
        }
      }
      if(this.references.length > 0){
        pdf.setFont('arial','bold');
        pdf.text('References',60,start);
        pdf.setFont('arial','normal');
        start +=25;
        if(start >= 700){
          pdf.addPage();
          start=15;
        }
        for(var i = 0; i < this.references.length;i++){
          var text1 = this.references[i].name + "";
          var t2 = "";
          if(this.references[i].addr2 != 'null'){
            t2 = this.references[i].addr + ", " + this.references[i].addr2 + ", " + this.references[i].city + ", " + this.references[i].state + " " + this.references[i].zip;
          }else{
            t2 = this.references[i].addr + ", " + this.references[i].city + ", " + this.references[i].state + " " + this.references[i].zip;
          }
          var t3 = "Contact: " + this.references[i].phone + " - " + this.references[i].email;
          pdf.text(text1,80,start);
          start += 15;
          pdf.text(t2,80,start);
          start += 15;
          pdf.text(t3,80,start);
          start +=25;
          if(start >= 700){
            pdf.addPage();
            start=15;
          }
        }
      }
    }
    if(this.attachments.length > 0){
      for(var i=0;i<this.attachments.length;i++){
        if(!onNew){
          pdf.addPage();
        }else{
          onNew = false;
        }
        pdf.addImage(this.attachments[i],'JPEG',0,0,612,792,"efile" + i,null,0);
      }
    }
    pdf.save(this.profile.lname + ".pdf");
  }
}