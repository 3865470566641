import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CaliLegalComponent } from 'src/app/cali-legal/cali-legal.component';
import { CaliOptOutComponent } from 'src/app/cali-opt-out/cali-opt-out.component';
import { LegalComponent } from 'src/app/legal/legal.component';
import { PrivacyComponent } from 'src/app/privacy/privacy.component';
import { TermsComponent } from 'src/app/terms/terms.component';

@Component({
  selector: 'cup-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }
  //these are for the popups
  openTerms(){
    const dialogRef = this.dialog.open(TermsComponent, {
      width: '750px',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  privacyPolicy() {
    const dialogRef = this.dialog.open(PrivacyComponent, {
      width: '750px',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  legalPolicy() {
    const dialogRef = this.dialog.open(LegalComponent, {
      width: '750px',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  calLegalPolicy() {
    const dialogRef = this.dialog.open(CaliLegalComponent, {
      width: '750px',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  calOptOut() {
    const dialogRef = this.dialog.open(CaliOptOutComponent, {
      width: '750px',
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
