import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { AddDialogComponent } from './add-dialog/add-dialog.component';
import { LicenseService } from 'src/app/api/license.service';
import { UserService } from 'src/app/api/user.service';
import { Subscription } from 'rxjs';
import { FileViewDialogComponent } from '../file-view-dialog/file-view-dialog.component';
import { DeleteService } from 'src/app/api/delete.service';
import { UpdateLicensesDialogComponent } from './update-licenses-dialog/update-licenses-dialog.component';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { PicklistService } from 'src/app/api/picklist.service';
import { UtilService } from 'src/app/api/util.service';
import { AddDocumentDialogComponent } from '../add-document-dialog/add-document-dialog.component';
import { AddDocumentService } from 'src/app/api/add-document.service';

@Component({
  selector: 'cup-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.css']
})
export class LicensesComponent implements OnInit {
  private user: any;
  private licenseSubscription: Subscription;
  private deleteSubscription: Subscription;
  private picklistSubscription: Subscription;
  private documentSubscription: Subscription;
  public licenses: any[] = [];
  public deaLicenses: any[] = [];
  public data: FormGroup;
  public states;
  private saving = false;
  constructor(public fbuilder: FormBuilder,public dialog: MatDialog, private userService: UserService, private licenseService: LicenseService,private deleteService: DeleteService,
    public snackbar: MatSnackBar,public picklistService: PicklistService,private addDocumentService: AddDocumentService,private utilService: UtilService) { }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.licenseService.getLicense(this.user);
    this.licenseSubscription = this.licenseService.licenseChanged.subscribe(licenses =>{
      if(licenses.status === 'fail'){
        this.snackbar.open('Error inserting record','',{duration: 3000, panelClass: ['snackbar-error']});
      }else{
        this.licenses = licenses.data.filter(type => type.type != "D");
        this.deaLicenses = licenses.data.filter(type => type.type === "D");
        if (this.saving) {
          this.clearForm();
          this.snackbar.open('License Entry added', '', { duration: 2000, panelClass: ['snackbar-success'] });
        }
      }
    });
    this.deleteSubscription = this.deleteService.valueChanged.subscribe(licenses =>{
      if(licenses != null){
        this.licenses = licenses.data.filter(type => type.type != "D");
        this.deaLicenses = licenses.data.filter(type => type.type === "D");
        this.snackbar.open('License Entry Deleted','',{duration: 2000,panelClass: ['snackbar-success']});
      }
    });
    this.documentSubscription = this.addDocumentService.statusChanged.subscribe(status => {
      if(status.status == "success"){
        this.snackbar.open('Document added to Record','',{duration: 2000,panelClass: ['snackbar-success']});
        this.licenseService.getLicense(this.user);
      }else{
        this.snackbar.open('Error adding Document to Record','',{duration: 2000,panelClass: ['snackbar-error']});
      }
    });
    this.data = this.fbuilder.group({
      licenseType:  new FormControl('', Validators.required),
      licenseDesc:  new FormControl('', Validators.required),
      licenseNumber:  new FormControl('', Validators.required),
      state:  new FormControl('', Validators.required),
      picker:  new FormControl('', Validators.required),
      expiration:  new FormControl('', Validators.required),
      practicing:  new FormControl('', Validators.required),
      fileSource: ''
    });
    //add call for state picklist
    this.picklistService.getStates();
    this.picklistSubscription = this.picklistService.statesChanged.subscribe(states => {
      this.states = states.data;
    });
  }
  ngOnDestroy(){
    this.licenseSubscription.unsubscribe();
    this.deleteSubscription.unsubscribe();
    this.picklistSubscription.unsubscribe();
  }
  public onFileChange(event) {
    const file = event.target.files[0];
    var check = this.utilService.checkFileExtention(file);
    if(check){
      this.data.patchValue({
        fileSource: file
      });
    }else{
      var myFile = document.getElementById('file') as HTMLInputElement;
      myFile.value = '';
      alert('File Type Not Accepted');
    }
  }
  public openDialog(file: any){
    var dialogRef = this.dialog.open(FileViewDialogComponent,{
      width: "1024px",
      data: file
    });
  }
  public add(){
    if(this.data.status == "INVALID"){
      alert("Please ensure all fields have been completed.");
    }else{
      var formData = new FormData();
      formData.append("i_Date", this.utilService.formatDate(this.data.value.picker));
      formData.append("e_Date", this.utilService.formatDate(this.data.value.expiration));
      formData.append("type", this.data.value.licenseType);
      formData.append("desc",this.data.value.licenseDesc);
      formData.append("num", this.data.value.licenseNumber);
      formData.append("state", this.data.value.state);
      formData.append("prac", this.data.value.practicing);
      formData.append("fileSource", this.data.value.fileSource);
      this.licenseService.addLicense(this.user, formData);
      this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
      this.saving = true;
    }
  }
  public openEditDialog(record: any){
    var dialogRef = this.dialog.open(UpdateLicensesDialogComponent,{
      width: "500px",
      data: record
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined && result != null){
        this.licenseService.updateLicense(this.user, result);
        this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
        this.saving = true;
      }
    });
  }
  public openAddDocDialog(record: any){
    var data = {"id": record.id,"type": "T_LICENSE"};
    var dialogRef = this.dialog.open(AddDocumentDialogComponent,{
      width: "500px",
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined && result != null){
        this.addDocumentService.addDocument(this.user,result);
        this.snackbar.open('Save in Progress','Please wait...',{duration: 2000,panelClass: ['snackbar-neutral']});
      }
    });
  }
  public deleteLicense(id: string){
    let del = confirm("Are you sure?");
    if(del){
      this.deleteService.deleteEntry(this.user,'T_LICENSE',id);
    }
  }
  public resetForm(){
    this.clearForm();
  }
  private clearForm(){
    var file = document.getElementById('file') as HTMLInputElement;
    file.value = '';
    this.data.reset();
  }
}
function UpdateLicenseDialogComponent(UpdateLicenseDialogComponent: any, arg1: { width: string; data: any; }) {
  throw new Error('Function not implemented.');
}

