import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeleteService {
  public value: any;
  public valueChanged = new Subject<any>();
  
  constructor(private http: HttpClient) { }

  public deleteEntry(userId:String,type: String, id: any){
      this.http.get(`/rest/deletePanels.php?user=${userId}&id=${id}&type=${type}`).subscribe(status => {
        this.value= status;
        this.valueChanged.next(this.value);
      });
  }
}
