import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/api/user.service';

@Component({
  selector: 'cup-find-username',
  templateUrl: './find-username.component.html',
  styleUrls: ['./find-username.component.css']
})
export class FindUsernameComponent implements OnInit {
  public data: any;
  public userSubscription = new Subscription;
  constructor(public dialogRef: MatDialogRef<FindUsernameComponent>,public fbuilder: FormBuilder,private userService: UserService,public snackbar: MatSnackBar) { 
    this.data = this.fbuilder.group({
      first: new FormControl('',Validators.required),
      last: new FormControl('',Validators.required),
      email: new FormControl('',[Validators.required, Validators.email])
    });
  }

  ngOnInit() {
    this.userSubscription = this.userService.usernameChanged.subscribe(user => {
      if(user.status != "fail"){
        alert("Your username is " + user.username);
      }else{
        alert("No user found matching provided information");
      }
    });
  }
  ngOnDestroy(){
    this.userSubscription.unsubscribe();
  }
  public retrieve(){
    if(this.data.status == "INVALID"){
      alert("Please ensure all fields have been completed.");
    }else{
      var formData = new FormData();
      formData.append("first", this.data.value.first);
      formData.append("last", this.data.value.last);
      formData.append("email", this.data.value.email);
      this.userService.findUsername(formData);
    }
  }
  public cancel(){
    this.dialogRef.close();
  }
}
